import { TABS } from '../Constants';

const initialState = {
  tabValue: TABS.TAB_MEDIA_LIBRARY,
};

export const ACTIONS = {
  TAB_VALUE_SUCCES: 'TAB_VALUE_SUCCES',
};

export const setTabValue = tabValue => ({
  type: ACTIONS.TAB_VALUE_SUCCES,
  payload: tabValue,
});

export default (state = initialState, action) => {
  switch (action.type) {
    case ACTIONS.TAB_VALUE_SUCCES:
      return {
        ...state,
        tabValue: action.payload,
      };

    default:
      return state;
  }
};
