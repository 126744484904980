// File: LocaleTools.jsx

/**
 * Returns the locale wanted by the user
 * THIS FUNCTION CAN BE FACTORIZED IN AN EXTERNAL LIBRARY
 * @param {*} defaultLocale
 * @param {*} availableTranslations
 * @returns
 */
// eslint-disable-next-line import/prefer-default-export
export const getUserLocale = (defaultLocale, availableTranslations) => {
  // default locale
  let lang = defaultLocale;

  // Set this variable to true to force english version
  // Set it to false to try to use user preferred locale
  const forceInEnglish = false;

  // ckeck if the locale is set in the URL
  const localeSetInUrl = window.location.search.replace('?locale=', '');
  if (localeSetInUrl.length > 0) {
    lang = localeSetInUrl;
  } else if (!forceInEnglish) {
    // Ckeck browser preferred language
    if (navigator.languages && navigator.languages.length) {
      // latest versions of Chrome and Firefox set this correctly
      const [firstlanguage] = navigator.languages;
      lang = firstlanguage;
    } else if (navigator.userLanguage) {
      // IE only
      lang = navigator.userLanguage;
    } else {
      // latest versions of Chrome, Firefox, and Safari set this correctly
      lang = navigator.language;
    }
    const pos = lang.indexOf('-');
    if (pos > 0) {
      lang = lang.substr(0, pos);
    }

    // End of the block that should be removed to force english version
  }

  if (typeof availableTranslations[lang] === 'undefined') {
    console.log(`The wanted locale '${lang}' is not available, the default one will be used: '${defaultLocale}'`);
    lang = defaultLocale;
  }

  return lang;
};
