import React, { Component } from 'react';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import { StylesProvider, createGenerateClassName } from '@material-ui/core/styles';
import configureStore, { history } from './reducers/configureStore';
import Viewport from './containers/Viewport';
import Routes from './Routes';
import { Features } from './Features';
import './assets/scss/index.scss';

const store = configureStore(/* provide initial state if any */);

/**
 * To fix issues between jss classes from the SkewerUI library and the Editor
 * Add a prefix on the jss from the editor
 */
const generateClassName = createGenerateClassName({
  productionPrefix: 'skwrcrop',
});

export default class App extends Component {
  constructor() {
    super();
    const features = new Features(store);
    // skaf is the var inside the skf.js file at the root of the editor,
    // loaded by the main html file
    features.setTechnicalAvailability(window.skaf);
  }

  render() {
    return (
      <StylesProvider generateClassName={generateClassName}>
        <Provider store={store}>
          <Viewport>
            <ConnectedRouter history={history}>
              {Routes}
            </ConnectedRouter>
          </Viewport>
        </Provider>
      </StylesProvider>
    );
  }
}
