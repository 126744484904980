import React from 'react';
import { injectIntl } from 'react-intl';
import {
  SkwrButton,
  SkwrDialog,
} from 'skewerui';
import { commonMessages } from '../Constants';

const InformationDialog = ({
  intl: { formatMessage },
  available,
  titleDialog,
  textDialog,
  onClose,
}) => {

  const handleClose = () => {
    onClose();
  };

  return (
    <SkwrDialog
      open={!available}
      onClose={handleClose}
      title={titleDialog}
      actions={[
        <SkwrButton
          componentStyle="ButtonOutlined"
          onClick={handleClose}
          key="InfoDialogOkButton"
        >
          {formatMessage(commonMessages.okButton)}
        </SkwrButton>,
      ]}
    >
      {textDialog}
    </SkwrDialog>
  );

};

export default injectIntl(InformationDialog);
