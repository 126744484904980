
/**
 * Get number of pending requests
 * @param {*} state The State
 */
export const getNbPendingRequests = (state) => {
  return (state.pendingRequests.getRequestsCounter + state.pendingRequests.postRequestsCounter);
};
export const isTherePendingRequests = (state) => {
  return (getNbPendingRequests(state) !== 0);
};

/**
 * Get number of pending GET requests
 * @param {*} state The State
 */
export const getNbPendingGetRequests = (state) => {
  return state.pendingRequests.getRequestsCounter;
};
export const isTherePendingGetRequests = (state) => {
  return (getNbPendingGetRequests(state) !== 0);
};

/**
 * Get number of pending POST requests
 * @param {*} state The State
 */
export const getNbPendingPostRequests = (state) => {
  return state.pendingRequests.postRequestsCounter;
};
export const isTherePendingPostRequests = (state) => {
  return (getNbPendingPostRequests(state) !== 0);
};
