import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { injectIntl, defineMessages } from 'react-intl';
import { Color, FontSizes } from 'skewerui';

const styles = {
  loadingMessageDiv: {
    fontSize: FontSizes.textSmall,
    fontStyle: 'italic',
    color: Color.orange,
  },
};

const messages = defineMessages({
  loadingMessage: {
    id: 'LoadingViewer.loadingMessage',
    defaultMessage: 'Loading',
  },
  savingMessage: {
    id: 'LoadingViewer.savingMessage',
    defaultMessage: 'Saving',
  },
});

const LoadingViewer = ({
  intl: { formatMessage },
  classes,
  pendingGetRequests,
  pendingPostRequests,
}) => {
  const isLoading = () => {
    if (pendingPostRequests) {
      return formatMessage(messages.savingMessage);
    }
    if (pendingGetRequests) {
      return formatMessage(messages.loadingMessage);
    }
    return '';
  };

  return (
    <label className={classes.loadingMessageDiv}>
      {isLoading()}
    </label>
  );
};

export default injectIntl(withStyles(styles)(LoadingViewer));
