import React, { useState } from 'react';
import classNames from 'classnames';
import { injectIntl, defineMessages } from 'react-intl';
import { makeStyles } from '@material-ui/core/styles';
import Select from '@material-ui/core/Select';
import {
  SkwrButton,
  SkwrSelectItem,
  SkwrToolBar,
} from 'skewerui';
import CONSTANTS, { getAspectRatioMessageID } from '../Constants';

const messages = defineMessages({
  aspectRatio: {
    id: 'medias.aspectRatio',
    defaultMessage: 'Aspect ratio :',
  },
  generate: {
    id: 'medias.generate',
    defaultMessage: 'Generate',
  },
});

const useStyles = makeStyles(() => ({
  toolbar: {
    marginBottom: '20px',
    padding: '10px 20px',
  },
  selectRatio: {
    marginLeft: '10px',
    marginRight: '20px',
  },
  mediasActions: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    marginLeft: '40px',
  },
  mediasActionsForm: {
    display: 'flex',
    flexDirection: 'column',
    marginRight: '32px',
  },
}));

const AspectRatioSelector = ({
  className,
  intl: { formatMessage },
  defaultValue,
  onChange,
  onClick,
  disabled,
}) => {
  const classes = useStyles();

  const [value, setValue] = useState(defaultValue);
  const handleOnChange = (evt) => {
    setValue(evt.target.value);
    onChange(evt.target.value);
  };

  return (
    <SkwrToolBar className={classNames(className, classes.toolbar)}>
      <label>{formatMessage(messages.aspectRatio)}</label>
      <Select
        className={classes.selectRatio}
        onChange={handleOnChange}
        value={value}
      >
        {CONSTANTS.AUTOCROP_RATIOS.map(
          (ratio, index) => (
            <SkwrSelectItem
              key={index}
              value={ratio}
            >
              {formatMessage(getAspectRatioMessageID(ratio))}
            </SkwrSelectItem>
          ),
        )}
      </Select>
      <SkwrButton
        disabled={disabled}
        onClick={onClick}
      >
        {formatMessage(messages.generate)}
      </SkwrButton>
    </SkwrToolBar>
  );
};

export default injectIntl(AspectRatioSelector);
