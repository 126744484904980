import React from 'react';
import { injectIntl, defineMessages } from 'react-intl';
import classNames from 'classnames';
import dayjs from 'dayjs';
import { makeStyles } from '@material-ui/core/styles';
import {
  SkwrCheckbox,
  SkwrCheckboxStyles,
  SkwrTooltip,
  Color,
} from 'skewerui';
import { getAspectRatioMessageID } from '../Constants';
import { getAspectRatioIcon } from '../tools/AssetTools';

const messages = defineMessages({
  downloadAsset: {
    id: 'croppedAssetsList.downloadAsset',
    defaultMessage: 'Download',
  },
});

const useStyles = makeStyles(() => ({
  lineTable: {
    height: '30px',
    border: `1px solid ${Color.greyLight3}`,
    backgroundColor: `${Color.white}`,
  },
  alignLeft: {
    textAlign: 'left',
  },
  assetAspectRatio: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    '& svg': {
      width: '24px',
      height: '24px',
      marginRight: '8px',
    },
  },
  ajustAlign: {
    verticalAlign: 'middle',
  },
  dateTableCase: {
    width: '170px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  statusItem: {
    display: 'flex',
    justifyContent: 'center',
  },
  exportInfos: {
    margin: '0px 5px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    width: '154px',
    flexShrink: 0,
  },
  exportInfosNameDiv: {
    margin: '0px 5px',
    width: '100%',
    display: 'flex',
  },
  exportInfosNameLabel: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  statusSvgLoading: {
    height: '20px',
    width: '20px',
    '& svg': {
      fill: `${Color.grey}`,
    },
  },
  statusSvgComplete: {
    height: '20px',
    width: '20px',
    '& svg': {
      '& path': {
        fill: `${Color.green}`,
      },
    },
  },
  statusSvgError: {
    height: '20px',
    width: '20px',
    '& svg': {
      fill: `${Color.red}`,
    },
  },
  action: {
    marginRight: '10px',
    textAlign: 'right',
    textTransform: 'uppercase',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
}));

const ResultItem = (props) => {
  const {
    intl: { formatMessage },
    croppedAsset,
    onDownload,
    onCheckItem,
    isChecked,
  } = props;

  const classes = useStyles();
  const exportItemName = React.useRef(null);

  const getStyle = () => {
    return { color: Color.greyDark, cursor: 'pointer' };
  };

  const date = dayjs(croppedAsset.createdAt);

  const handleOnChange = () => {
    onCheckItem(croppedAsset);
  };

  const handleClickOnDownload = () => {
    onDownload(croppedAsset);
  };

  return (
    <tr className={classes.lineTable}>
      <th style={{ width: '30px' }}>
        <SkwrCheckbox
          componentStyle={SkwrCheckboxStyles.PRIMARY}
          checked={isChecked}
          onChange={handleOnChange}
        />
      </th>
      <th className={classNames(classes.alignLeft, classes.dateTableCase)}>
        <SkwrTooltip title={date.format('LLL')}>
          <div className={classes.exportInfos}>{date.format('L LT')}</div>
        </SkwrTooltip>
      </th>
      <th className={classes.alignLeft}>
        <div className={classes.exportInfosNameDiv}>
          <SkwrTooltip
            ellipsisref={exportItemName}
          >
            <label
              ref={exportItemName}
              className={classes.exportInfosNameLabel}
            >
              {croppedAsset.name}
            </label>
          </SkwrTooltip>
        </div>
      </th>
      <th className={classNames(classes.alignLeft, classes.ajustAlign)}>
        <div className={classes.assetAspectRatio}>
          {getAspectRatioIcon(croppedAsset.aspectRatio)}
          {formatMessage(getAspectRatioMessageID(croppedAsset.aspectRatio))}
        </div>
      </th>
      <th>
        <div
          className={classes.action}
          style={getStyle()}
          role="button"
          onClick={handleClickOnDownload}
        >
          {formatMessage(messages.downloadAsset)}
        </div>
      </th>
    </tr>
  );
};
export default injectIntl(ResultItem);
