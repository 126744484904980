import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import { makeStyles } from '@material-ui/core/styles';
import { FontSizes, IS_MACOS, isKeyEventForSelectAll } from 'skewerui';
import AssetItem from './AssetItem';
import { getAssetIRI } from '../tools/IRITools';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    height: '100%',
    overflowX: 'hidden',
    overflowY: 'scroll',
    flexDirection: 'column',
    // following styles to mask the fact that we use a button
    border: 'none',
    backgroundColor: 'transparent',
    outline: 'none',
  },
  gridList: {
    width: 'calc(100% + 3px)',
    display: 'flex',
    flexWrap: 'wrap',
    alignContent: 'flex-start',
    userSelect: 'none',
    marginBottom: '30px',
  },
  gridListItemContainer: {
    outline: 'none',
    width: 'calc((100vw / 6) - 10px)',
    height: 'calc((100vw / 6) - 10px)',
    minHeight: '180px',
    minWidth: '180px',
    maxHeight: '230px',
    maxWidth: '230px',
    position: 'relative',
    boxSizing: 'border-box',
    margin: 5,
  },
  headingBar: {
    padding: 16,
  },
  headingBarTitle: {
    marginBottom: 6,
    fontSize: 11,
  },
  catTitle: {
    padding: '10px',
    fontSize: FontSizes.titleThree,
  },
  onlyLabel: {
    width: '100%',
    marginBottom: '30px',
  },
}));

// Beware that ASSETELEMPREFIX is also used in MediaLibrary.jsx file !
export const ASSETELEMPREFIX = 'assetItem_';
const assetListElemID = (assetIRI) => `${ASSETELEMPREFIX}${assetIRI}`;
const assetIRIFromElemID = (elemID) => {
  if (elemID.startsWith(ASSETELEMPREFIX)) {
    return elemID.slice(ASSETELEMPREFIX.length);
  }
  return null;
};

const AssetsListing = (props, ref) => {
  const {
    className,
    assets,
    children,
    onAssetNameChanged,
    onSelectionChanged,
    onDeleteAssets,
    onGenerateCroppedVersion,
    displayRealFileName,
    getCroppedAssetsFromAsset,
    ...restProps
  } = props;

  const [selectedAssets, setSelectedAssets] = useState([]);
  const classes = useStyles();

  const updateSelection = (newSelection) => {
    setSelectedAssets(newSelection);

    if ((typeof onSelectionChanged !== 'undefined')
     && (onSelectionChanged !== null)) {
      onSelectionChanged(newSelection);
    }
  };

  useEffect(() => {
    const newSelection = [];

    // Reset selection according to new assets
    selectedAssets.forEach((assetIRI) => {
      let found = false;
      for (let i = 0; ((i < assets.length) && (!found)) ; i += 1) {
        if (assetIRI === getAssetIRI(assets[i])) {
          found = true;
        }
      }
      if (found === true) {
        newSelection.push(assetIRI);
      }
    });
    updateSelection(newSelection);
  }, [assets]);


  function handlerKeyDown(e) {
    const isShiftPressed = (e.code === 'ShiftLeft' || e.shiftKey);

    if (e.key === 'ArrowRight') {
      arrowAssetSelection('ArrowRight', isShiftPressed, true);
    } else if (e.key === 'ArrowLeft') {
      arrowAssetSelection('ArrowLeft', isShiftPressed, true);
    } else if (e.key === 'Tab' && isShiftPressed) {
      arrowAssetSelection('ArrowLeft');
    } else if (e.key === 'Tab') {
      arrowAssetSelection('ArrowRight');
    }

    if (e.key === 'Delete') {
      handleDeleteRequest();
      return;
    }

    if (isKeyEventForSelectAll(e)) {
      selectAllAssets();
      e.preventDefault();
    }
  }// handlerKeyDown

  function arrowAssetSelection(arrow, isShiftPressed = false, giveFocus = false) {
    let focusedAssetIRI = assetIRIFromElemID(document.activeElement.id);
    if (focusedAssetIRI === null) {
      focusedAssetIRI = selectedAssets[selectedAssets.length - 1];
    }
    const lastSelectionIdx = assets.findIndex((elem) => getAssetIRI(elem) === focusedAssetIRI);

    // nIdx define if we want the next or previous assetItem
    let nIdx = 1;
    if (arrow === 'ArrowLeft') {
      nIdx = -1;
    }

    const nextAssetIdx = lastSelectionIdx + nIdx;
    const nextAsset = assets[nextAssetIdx];
    const nextAssetIRI = getAssetIRI(assets[nextAssetIdx]);
    const foundIdx = selectedAssets.indexOf(nextAssetIRI);

    // Check if nextAsset !== undefined
    if (nextAsset) {
      if (isShiftPressed) {
        // nextAssetIRI is in selectedAssets ?
        if (foundIdx !== -1) {
          // yes -> remove lastItem of selectedAssets
          eitherAddOrRemoveSelectedAsset(focusedAssetIRI);
        } else {
          // no -> add this next item
          eitherAddOrRemoveSelectedAsset(nextAssetIRI);
          if (giveFocus) setFocusOnAsset(nextAssetIRI);
        }
        // RETURN to not run updateSelection([nextAssetIRI]) !!!
        return;
      }

      updateSelection([nextAssetIRI]);
      if (giveFocus) setFocusOnAsset(nextAssetIRI);
    }
  }

  const setFocusOnAsset = (assetIRI) => {
    const assetItemID = assetListElemID(assetIRI);

    const elem = document.getElementById(assetItemID);
    if ((typeof elem !== 'undefined')
     && (elem !== null)) {
      elem.focus();
    }
  };

  const handleDeleteRequest = () => {
    if (selectedAssets.length > 0) {
      if ((typeof onDeleteAssets !== 'undefined')
       && (onDeleteAssets !== null)) {
        onDeleteAssets();
      }
    }
  };

  /**
   * Handle click on an asset
   * @param {*} assetIRI The IRI of the clicked asset
   * @param {*} evt click event
   */
  const handleClickOnAsset = (assetIRI, assetIdx, evt) => {
    let isCtrlPressed = false;
    if (IS_MACOS) {
      if (evt.metaKey) {
        isCtrlPressed = true;
      }
    } else {
      isCtrlPressed = (evt.code === 'ControlLeft' || evt.ctrlKey);
    }

    const isShiftPressed = (evt.code === 'ShiftLeft' || evt.shiftKey);

    if (isCtrlPressed) {
      eitherAddOrRemoveSelectedAsset(assetIRI);
    } else if (isShiftPressed && selectedAssets.length !== 0) {
      selectAssetRange(assetIdx);
    } else {
      // the clicked item is the selected item
      updateSelection([assetIRI]);
    }
    // Click on asset item div -> stopPropagation to not enter in the above handler
    evt.stopPropagation();
  };

  /**
   * Either add or remove the asset from the selection
   * @param {*} assetIRI The IRI of asset
   */
  function eitherAddOrRemoveSelectedAsset(assetIRI) {
    const newSelection = [...selectedAssets];
    const foundIdx = newSelection.indexOf(assetIRI);

    if (foundIdx !== -1) {
      if (newSelection.length >= 1) {
        newSelection.splice(foundIdx, 1);
        updateSelection(newSelection);
      }
    } else {
      newSelection.push(assetIRI);
      updateSelection(newSelection);
    }
  }

  /**
   * select a range of assets
   * @param {*} assetIdx The index of asset
   */
  function selectAssetRange(assetIdx) {
    const lastSelectionIRI = selectedAssets[selectedAssets.length - 1];
    const lastSelectionIdx = assets.findIndex(elem => getAssetIRI(elem) === lastSelectionIRI);
    if (lastSelectionIdx !== -1) {
      const startIdx = Math.min(assetIdx, lastSelectionIdx);
      const endIdx = Math.max(assetIdx, lastSelectionIdx) + 1;

      const newSelection = [];
      for (let i = startIdx; i < endIdx; i += 1) {
        newSelection.push(getAssetIRI(assets[i]));
      }// for
      updateSelection(newSelection);
    }
  }

  function selectAllAssets() {
    const newSelection = [];
    assets.forEach((asset) => {
      newSelection.push(getAssetIRI(asset));
    });
    updateSelection(newSelection);
  }

  const handleClickOnBackground = (evt) => {
    if (selectedAssets.length !== 0) {
      updateSelection([]);
      // If we reset the selection -> stopPropagation to not enter in the above handler
      evt.stopPropagation();
    }
  };

  const handleGenerateCroppedVersionFromItem = (asset, ratio) => {
    if ((typeof onGenerateCroppedVersion !== 'undefined')
     && (onGenerateCroppedVersion !== null)) {
      onGenerateCroppedVersion([asset], ratio);
    }
  };

  const displayAssets = () => {
    const assetsItems = [];
    for (let i = 0; i < assets.length; i += 1) {
      assetsItems.push(displayAsset(assets[i], i));
    }// for
    return assetsItems;
  };
  const displayAsset = (assetElem, index) => {
    const assetItemID = assetListElemID(getAssetIRI(assetElem));
    return (
      <div
        className={classes.gridListItemContainer}
        key={getAssetIRI(assetElem)}
        id={assetItemID}
        onClick={handleClickOnAsset.bind(this, getAssetIRI(assetElem), index)}
        role="button"
        tabIndex="0"
      >
        <AssetItem
          asset={assetElem}
          onAssetNameChanged={onAssetNameChanged}
          selected={isAssetSelected(assetElem)}
          displayRealFileName={displayRealFileName}
          croppedAssets={getCroppedAssetsFromAsset(assetElem)}
          onGenerateCroppedVersion={handleGenerateCroppedVersionFromItem}
        />
      </div>
    );
  };

  /**
   * I the given asset selected ?
   * @param {*} asset asset to check
   */
  const isAssetSelected = (asset) => {
    return selectedAssets.includes(getAssetIRI(asset));
  };

  return (
    <div
      className={classNames(className, classes.root)}
      {...restProps}
      onKeyDown={handlerKeyDown.bind(this)}
      role="button"
      tabIndex="-1"
      onClick={handleClickOnBackground.bind(this)}
    >
      <div
        className={classes.gridList}
        onClick={handleClickOnBackground.bind(this)}
        role="button"
      >
        {displayAssets()}
      </div>
    </div>
  );
};

export default AssetsListing;
