//
// BEWARE : FAKE
// This file should not exist!
// It does exist until the Back is able to give us following information for any user and
// any project
//
export const fakeUserFeatureAccessRights = {
  // NOT LINKED TO ANY USER OR PROJECT
  SIGN_UP: true,
  LOG_IN: true,
  LOST_PASSWORD: true,

  // LINKED TO USER BUT NOT TO PROJECT
  CREATE_PROJECT: true,

  // LINKED TO USER AND PROJECT
  DELETE_PROJECT: true,
  ADD_DATA_KEY: true,

  MODIFY_PROJECT_SETTINGS: true,
  MANAGE_MEDIA_LIBRARY: true,

  ADD_LABEL: true,
};

export const fakeUserProjectFeatureAccessRights = {
  // NOT LINKED TO ANY USER OR PROJECT
  SIGN_UP: true,
  LOG_IN: true,
  LOST_PASSWORD: true,

  // LINKED TO USER BUT NOT TO PROJECT
  CREATE_PROJECT: true,

  // LINKED TO USER AND PROJECT
  DELETE_PROJECT: true,
  ADD_DATA_KEY: true,

  MODIFY_PROJECT_SETTINGS: true,
  MANAGE_MEDIA_LIBRARY: true,

  ADD_LABEL: true,
};
