import React, { useEffect, useState, useRef } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { SkwrSnackBarError } from 'skewerui';

import View from '../components/View';

import HeaderAutocrop from '../containers/Header/HeaderAutocrop';
import MediaLibrary from '../mediaLibrary/MediaLibrary';

import { getLastError } from '../reducers/mediaLibrarySelector';
import * as MediaLibraryActions from '../reducers/mediaLibraryActions';

import { commonMessages } from '../Constants';

const Medias = ({
  intl: { formatMessage },
  lastError,
  mediaLibraryActions,
}) => {
  const [openErrorSnackbar, setOpenErrorSnackbar] = useState(false);

  useEffect(() => {
    if (lastError) {
      setOpenErrorSnackbar(true);
    } else {
      setOpenErrorSnackbar(false);
    }
  }, [lastError]);

  const getErrorMessage = () => {
    if ((typeof lastError === 'undefined') || (lastError === null)) {
      return '';
    }
    const errorKey = lastError;

    let errorMessage = '';
    if (typeof commonMessages[errorKey] !== 'undefined') {
      errorMessage = formatMessage(commonMessages[errorKey]);
    } else {
      const param = { internalErrorCode: errorKey };
      errorMessage = formatMessage(commonMessages.internalError, param);
    }
    return errorMessage;
  };// getErrorMessage

  const handleOnCloseErrorSnackBar = () => {
    mediaLibraryActions.resetError();
  };

  return (
    <View
      loggedIn="true"
      pageConnectedUser
      appDedicatedHeader={(
        <HeaderAutocrop />
      )}
    >
      <MediaLibrary />
      <SkwrSnackBarError
        message={getErrorMessage()}
        toggleError={openErrorSnackbar}
        onClose={handleOnCloseErrorSnackBar}
      />
    </View>
  );
};

export default connect(
  (state) => ({
    lastError: getLastError(state),
  }),
  (dispatch) => ({
    mediaLibraryActions: bindActionCreators(MediaLibraryActions, dispatch),
  }),
)(injectIntl(Medias));
