import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { injectIntl, defineMessages, FormattedMessage } from 'react-intl';
import dayjs from 'dayjs';
import { makeStyles } from '@material-ui/core/styles';
import Select from '@material-ui/core/Select';
import {
  SkwrButton,
  SkwrCheckboxUpDown,
  SkwrDropzone,
  SkwrQuestionDialog,
  SkwrSelectItem,
  SkwrTool,
  SkwrToolBar,
  ToolDeleteSVG,
  openFileSystemDialog,
} from 'skewerui';
import InformationDialog from '../components/InformationDialog';
import AspectRatioSelector from './AspectRatioSelector';
import AssetLoader from './AssetLoader';
import AssetsListing from './AssetsListing';

import * as MediaLibraryActions from '../reducers/mediaLibraryActions';
import CONSTANTS, { commonMessages } from '../Constants';

import {
  getAssets,
  isUploadInProgress,
  getUploadingFiles,
  getTotalNumberOfAssetsToUpload,
  getNumberOfUploadedAssets,
  findAlreadyExistingAssets,
  getAssetFromIRISelector,
  getCroppedAssetsFromAssetSelector,
} from '../reducers/mediaLibrarySelector';

import {
  setPreference,
  getPreference,
  PREFERENCES,
} from '../helpers/preferences';

import { getAllAcceptedFileTypes, getAssetFileName } from '../tools/AssetTools';

const messages = defineMessages({
  addMediaButton: {
    id: 'medias.addMediaButton',
    defaultMessage: 'Add Media',
  },
  deleteAssetsTooltip: {
    id: 'medias.deleteAssetsTooltip',
    defaultMessage: 'Delete selected media',
  },
  noMedia: {
    id: 'medias.noMedia',
    defaultMessage: 'No Media',
  },
  uploadInProgress: {
    id: 'medias.uploadInProgress',
    defaultMessage: 'upload in progress',
  },
  alreadyExistingAssetsTitle: {
    id: 'medias.alreadyExistingAssetsTitle',
    defaultMessage: 'Import existing media ?',
  },
  alreadyExistingAssetsMessage: {
    id: 'medias.alreadyExistingAssetsMessage',
    defaultMessage: 'The following files are already in the media library.',
  },
  alreadyExistingAssetsQuestion: {
    id: 'medias.alreadyExistingAssetsQuestion',
    defaultMessage: 'Do you want to upload them anyway ? ',
  },
  uploadAllAnswer: {
    id: 'medias.uploadAllAnswer',
    defaultMessage: 'Import all files',
  },
  uploadOnlyNewFilesAnswer: {
    id: 'medias.uploadOnlyNewFilesAnswer',
    defaultMessage: 'Import new files',
  },
  deleteAssetsConfirmTitle: {
    id: 'medias.deleteAssetsConfirmTitle',
    defaultMessage: 'Delete Media ?',
  },
  deleteAssetsAnswerButton: {
    id: 'medias.deleteAssetsAnswerButton',
    defaultMessage: 'Delete media',
  },
  informationDialogTitle: {
    id: 'medias.informationDialogTitle',
    defaultMessage: 'Information',
  },
  alreadyCroppedAsWanted: {
    id: 'medias.alreadyCroppedAsWanted',
    defaultMessage: 'All selected assets have already wanted cropped version',
  },
  sortByHeader: {
    id: 'medias.sortByHeader',
    defaultMessage: 'Sort',
  },
  sortByDate: {
    id: 'medias.sortByDate',
    defaultMessage: 'Date',
  },
  sortByAlphabetic: {
    id: 'medias.sortByAlphabetic',
    defaultMessage: 'A → Z',
  },
});

const useStyles = makeStyles(() => ({
  mediaContainer: {
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    verticalAlign: 'middle',
    flexDirection: 'column',
    position: 'relative',
  },
  mediaLibHeader: {
    width: '100%',
    boxSizing: 'border-box',
    paddingLeft: '20px',
    paddingRight: '20px',
    marginTop: '10px',
    marginBottom: '10px',
    display: 'flex',
    padding: '10px',
    flex: '0',
  },
  mediaLibHeaderCenter: {
    flex: 1,
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'flex-start',
  },
  mediaLibToolbar: {
    position: 'absolute',
    right: '20px',
    top: '20px',
  },
  mediaLibraryAssets: {
    width: '100%',
    padding: '5px',
    boxSizing: 'border-box',
    outline: 'none',
    flex: '1',
    overflow: 'hidden',
  },
  aspectRatioSelector: {
    flex: '0',
  },
}));

const compareObjectName = (a, b) => {
  return (a.name.localeCompare(b.name));
};

const compareAssetDate = (a, b) => {
  const dateA = dayjs(a.createdAt);
  const dateB = dayjs(b.createdAt);
  if (dateA.isAfter(dateB)) {
    return 1;
  }
  if (dateA.isBefore(dateB)) {
    return -1;
  }
  return 0;
};

const MediaLibrary = (props) => {
  const {
    intl: { formatMessage },
    assets,
    uploadInProgress,
    uploadingFiles,
    nbUploadTotal,
    nbUploadedAssets,
    mediaLibraryActions,
    findAlreadyExistingAssetsSelector,
    getAssetFromIRI,
    getCroppedAssetsFromAsset,
  } = props;

  const classes = useStyles();
  const dropzoneRef = useRef(null);

  const [selectedAssetsIRIs, setSelectedAssetsIRIs] = useState([]);
  const [ratio, setRatio] = useState(CONSTANTS.AUTOCROP_RATIOS[0]);

  const [askAQuestion, setAskAQuestion] = useState(false);
  const [questionDialogValues, setQuestionDialogValues] = useState({});

  const [informUser, setInformUser] = useState(false);

  const [sortedAssets, setSortedAssets] = useState([]);

  /**
   * The different kind of sorts available in the sort form
   */
  const SORT_BY_NAME = 'sortAlpha';
  const SORT_BY_DATE = 'sortDate';
  const SORT_BY = [
    { value: SORT_BY_NAME, msg: messages.sortByAlphabetic, cmpFunc: compareObjectName },
    { value: SORT_BY_DATE, msg: messages.sortByDate, cmpFunc: compareAssetDate },
  ];
  const [sortBy, setSortBy] = useState(
    getPreference(
      PREFERENCES.MEDIA_LIBRARY_ASSET_SORT_ORDER,
      SORT_BY_DATE,
    ),
  );
  const [sortRevert, setSortRevert] = useState(
    getPreference(
      PREFERENCES.MEDIA_LIBRARY_ASSET_SORT_DIRECTION,
      false,
    ),
  );

  /**
   * Effects have to be described after states !
   */
  useEffect(() => {
    mediaLibraryActions.retrieveAssets();
    mediaLibraryActions.retrieveCroppedAssets();
  }, []);

  useEffect(() => {
    setSortedAssets(sortAssets(assets));
  }, [assets, sortBy, sortRevert]);

  /**
   * Sort the assets according to the sort options
   */
  const sortAssets = () => {
    const newSortedAssets = [...assets];

    const sortToApply = SORT_BY.find((sort) => sort.value === sortBy);
    let compareFunction = compareAssetDate;
    if ((typeof sortToApply !== 'undefined')
     && (sortToApply !== null)) {
      compareFunction = sortToApply.cmpFunc;
    }
    newSortedAssets.sort(compareFunction);

    if (sortRevert) {
      newSortedAssets.reverse();
    }

    return newSortedAssets;
  };// sortAssets

  const handleSortChange = (event) => {
    setSortBy(event.target.value);

    setPreference(PREFERENCES.MEDIA_LIBRARY_ASSET_SORT_ORDER, event.target.value);
  };

  const handleSortRevertChange = (event) => {
    setSortRevert(event.target.checked);

    setPreference(PREFERENCES.MEDIA_LIBRARY_ASSET_SORT_DIRECTION, event.target.checked);
  };

  const onAspectRatioChanged = (newRatio) => {
    setRatio(newRatio);
  };

  const onSelectionChanged = (newSelection) => {
    setSelectedAssetsIRIs(newSelection);
  };

  const handleClickOnGenerateOnAspectRatioSelector = () => {
    const assetsToCrop = [];
    selectedAssetsIRIs.forEach((assetIRI) => {
      const asset = getAssetFromIRI(assetIRI);
      if ((typeof asset !== 'undefined')
       && (asset !== null)) {
        // Does the asset already have a version with this aspect ratio
        const croppedAssets = getCroppedAssetsFromAsset(asset);
        const idx = croppedAssets.findIndex((cpa) => (cpa.aspectRatio === ratio));
        if (idx === -1) {
          assetsToCrop.push(asset);
        }
      }
    });
    if (assetsToCrop.length !== 0) {
      generateCropVersion(assetsToCrop, ratio);
    } else {
      setInformUser(true);
    }
  };
  const handleGenerateCroppedVersionFromList = (assetsToCrop, aspectRatio) => {
    generateCropVersion(assetsToCrop, aspectRatio);
  };
  const generateCropVersion = (assetsToCrop, aspectRatio) => {
    assetsToCrop.forEach((assetToCrop) => {
      mediaLibraryActions.startCropJob(assetToCrop, aspectRatio);
    });
  };

  const getDeleteAssetsConfirmationMessage = (assetsToDelete) => {
    if (assetsToDelete.length === 1) {
      return (
        <FormattedMessage
          id="medias.deleteAssetConfirmMessage"
          defaultMessage="You are about to delete the file : <b>{mediaName}</b>."
          values={{
            // eslint-disable-next-line react/display-name
            b: (chunks) => (
              <span style={{ fontWeight: 'bold' }}>
                {chunks}
              </span>
            ),
            mediaName: assetsToDelete[0].name,
          }}
        />
      );
    }
    return (
      <FormattedMessage
        id="medias.deleteAssetsConfirmMessage"
        defaultMessage="You are about to delete <b>{nbMedia} media</b> files."
        values={{
          // eslint-disable-next-line react/display-name
          b: (chunks) => (
            <span style={{ fontWeight: 'bold' }}>
              {chunks}
            </span>
          ),
          nbMedia: assetsToDelete.length,
        }}
      />
    );
  };

  const handleDeleteAssets = () => {
    const assetsToDelete = [];
    if ((typeof selectedAssetsIRIs !== 'undefined')
     && (selectedAssetsIRIs !== null)
     && (selectedAssetsIRIs.length !== 0)) {
      selectedAssetsIRIs.forEach((assetIRI) => {
        const asset = getAssetFromIRI(assetIRI);
        if ((typeof asset !== 'undefined')
         && (asset !== null)) {
          assetsToDelete.push(asset);
        }
      });
    }
    if (assetsToDelete.length !== 0) {
      setQuestionDialogValues({
        title: formatMessage(messages.deleteAssetsConfirmTitle),
        question: getDeleteAssetsConfirmationMessage(assetsToDelete),
        btnYes: formatMessage(messages.deleteAssetsAnswerButton),
        btnNo: null,
        btnCancel: formatMessage(commonMessages.cancelButton),
        onYes: () => { deleteAssets(assetsToDelete); },
        onNo: null,
      });
      setAskAQuestion(true);
    }
  };
  const deleteAssets = (assetsToDelete) => {
    if (assetsToDelete.length !== 0) {
      mediaLibraryActions.deleteAssets(assetsToDelete);
    }
  };

  const handleAddMediaClick = () => {
    openFileSystemDialog(dropzoneRef);
  };

  const handleNewAssets = (newAssets) => {
    manageNewWantedAssets(newAssets);
  };

  const manageNewWantedAssets = (newWantedAssets) => {
    // Find the already existing asset that point on same file
    const {
      existingAssets,
      newFiles,
    } = findAlreadyExistingAssetsSelector(newWantedAssets);

    // If some of the given Files already exist in the dataBase,
    // ask the user if he really wants to upload them
    if (existingAssets.length > 0) {
      const title = formatMessage(messages.alreadyExistingAssetsTitle);

      const getDuplicateConfirmationMessage = () => {
        return (
          <>
            {formatMessage(messages.alreadyExistingAssetsMessage)}
            <p>
              {formatMessage(messages.alreadyExistingAssetsQuestion)}
            </p>
            <br />
            <ol type="1">
              {existingAssets.map((elem, index) => (
                <li key={index}>
                  {getAssetFileName(elem)}
                </li>
              ))}
            </ol>
          </>
        );
      };

      setQuestionDialogValues({
        title,
        question: getDuplicateConfirmationMessage(),
        btnYes: formatMessage(messages.uploadOnlyNewFilesAnswer),
        btnNo: formatMessage(messages.uploadAllAnswer),
        btnCancel: formatMessage(commonMessages.cancelButton),
        onYes: () => { createAndUploadNewAsset(newFiles); },
        onNo: () => { createAndUploadNewAsset(newWantedAssets); },
      });
      setAskAQuestion(true);
    } else {
      // None of the given files are already in the database
      createAndUploadNewAsset(newWantedAssets);
    }
  };// manageNewWantedAssets

  /**
   * Calls the action that creates the asset in the dataBase and that uploads the media
   */
  const createAndUploadNewAsset = (newAssets) => {
    if ((typeof newAssets !== 'undefined')
      && (newAssets !== null)
      && (newAssets.length !== 0)) {
      mediaLibraryActions.addAssetsToUploadQueue(newAssets);
    }
  };// createAndUploadNewAsset

  const renderMediaLibraryHeader = () => {
    const progressMessage = formatMessage(messages.uploadInProgress);

    return (
      <div className={classes.mediaLibHeader}>
        <SkwrButton
          onClick={handleAddMediaClick}
        >
          {formatMessage(messages.addMediaButton)}
        </SkwrButton>
        <AssetLoader
          uploadInProgress={uploadInProgress}
          uploadingFiles={uploadingFiles}
          nbUploadTotal={nbUploadTotal}
          nbUploadDone={nbUploadedAssets}
          uploadProgressionText={progressMessage}
        />
        <div
          className={classes.mediaLibHeaderCenter}
        >
          <Select
            value={sortBy}
            onChange={handleSortChange}
          >
            {SORT_BY.map((sort) => {
              let sortMsg = formatMessage(messages.sortByHeader);
              sortMsg = sortMsg.concat('\xa0\xa0\xa0\xa0');
              sortMsg = sortMsg.concat(formatMessage(sort.msg));
              return (
                <SkwrSelectItem
                  value={sort.value}
                  key={`asset_${sort.value}`}
                >
                  {sortMsg}
                </SkwrSelectItem>
              );
            })}
          </Select>
          <SkwrCheckboxUpDown
            checked={sortRevert}
            onChange={handleSortRevertChange}
          />
        </div>
        {selectedAssetsIRIs.length !== 0 && (
          <SkwrToolBar className={classes.mediaLibToolbar}>
            <SkwrTool
              SVGIcon={ToolDeleteSVG}
              tooltip={formatMessage(messages.deleteAssetsTooltip)}
              onClick={handleDeleteAssets}
            />
          </SkwrToolBar>
        )}
      </div>
    );
  };// renderMediaLibraryHeader

  return (
    <div className={classes.mediaContainer}>
      {askAQuestion && (
        <SkwrQuestionDialog
          isOpen={askAQuestion}
          titleDialog={questionDialogValues.title}
          textDialog={questionDialogValues.question}
          textBtnYes={questionDialogValues.btnYes}
          textBtnNo={questionDialogValues.btnNo}
          textBtnCancel={questionDialogValues.btnCancel}
          actionBtnNo={questionDialogValues.onNo}
          actionBtnYes={questionDialogValues.onYes}
          actionBtnCancel={() => setAskAQuestion(false)}
        />
      )}
      {informUser && (
        <InformationDialog
          isOpen={informUser}
          titleDialog={formatMessage(messages.informationDialogTitle)}
          textDialog={formatMessage(messages.alreadyCroppedAsWanted)}
          onClose={() => setInformUser(false)}
        />
      )}
      {renderMediaLibraryHeader()}
      <SkwrDropzone
        className={classes.mediaLibraryAssets}
        ref={dropzoneRef}
        onDropAccepted={handleNewAssets}
        accept={getAllAcceptedFileTypes().join(',')}
      >
        <AssetsListing
          assets={sortedAssets}
          onSelectionChanged={onSelectionChanged}
          onDeleteAssets={handleDeleteAssets}
          onGenerateCroppedVersion={handleGenerateCroppedVersionFromList}
          displayRealFileName
          getCroppedAssetsFromAsset={getCroppedAssetsFromAsset}
        />

      </SkwrDropzone>
      <AspectRatioSelector
        className={classes.aspectRatioSelector}
        defaultValue={ratio}
        onChange={onAspectRatioChanged}
        onClick={handleClickOnGenerateOnAspectRatioSelector}
        disabled={selectedAssetsIRIs.length === 0}
      />
    </div>
  );
};

export default connect(
  (state) => ({
    assets: getAssets(state),
    uploadInProgress: isUploadInProgress(state),
    uploadingFiles: getUploadingFiles(state),
    nbUploadTotal: getTotalNumberOfAssetsToUpload(state),
    nbUploadedAssets: getNumberOfUploadedAssets(state),
    findAlreadyExistingAssetsSelector: findAlreadyExistingAssets(state),
    getAssetFromIRI: getAssetFromIRISelector(state),
    getCroppedAssetsFromAsset: getCroppedAssetsFromAssetSelector(state),
  }),
  (dispatch) => ({
    mediaLibraryActions: bindActionCreators(MediaLibraryActions, dispatch),
  }),
)(injectIntl(MediaLibrary));
