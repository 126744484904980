import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { injectIntl, defineMessages } from 'react-intl';
import { withStyles } from '@material-ui/core/styles';
import * as RouterActions from 'connected-react-router';
import { SkwrTab, SkwrTabs } from 'skewerui';
import LoadingViewer from './LoadingViewer';
import ROUTING from '../../Routing';
import { TABS } from '../../Constants';
import { setTabValue } from '../../reducers/utilsState';
import {
  isTherePendingGetRequests,
  isTherePendingPostRequests,
} from '../../reducers/pendingRequestsSelectors';

const messages = defineMessages({
  tabMedias: {
    id: 'header.tabMedias',
    defaultMessage: 'Imported media',
  },
  tabResults: {
    id: 'header.tabResults',
    defaultMessage: 'Exported media',
  },
});

const styles = {
  HeaderEditor: {
    height: '100%',
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    margin: '0 16px',
  },
  leftControls: {
    display: 'flex',
    alignItems: 'center',
  },
};

const HeaderEditor = ({
  classes,
  intl: { formatMessage },
  routerActions,
  setTabValueAction,
  tabValue,
  thereIsSomeGetRequestsPending,
  thereIsSomePostRequestsPending,
}) => {
  const handleChange = (event, newValue) => {
    setTabValueAction(newValue);
    if (newValue === TABS.TAB_MEDIA_LIBRARY) {
      redirectTo(ROUTING.MEDIAS);
    }
    if (newValue === TABS.TAB_RESULTS) {
      redirectTo(ROUTING.RESULTS);
    }
  };

  const redirectTo = (URL) => {
    routerActions.push(URL);
  };

  return (
    <div className={classes.HeaderEditor}>
      <div className={classes.leftControls}>
        <SkwrTabs
          value={tabValue}
          onChange={handleChange}
        >
          <SkwrTab
            label={formatMessage(messages.tabMedias)}
            value={TABS.TAB_MEDIA_LIBRARY}
          />
          <SkwrTab
            label={formatMessage(messages.tabResults)}
            value={TABS.TAB_RESULTS}
          />
        </SkwrTabs>
      </div>
      <LoadingViewer
        pendingGetRequests={thereIsSomeGetRequestsPending}
        pendingPostRequests={thereIsSomePostRequestsPending}
      />
      <div />
    </div>
  );
};

const styledHeader = withStyles(styles)(HeaderEditor);
export default injectIntl(connect(
  (state) => ({
    tabValue: state.utils.tabValue,
    thereIsSomeGetRequestsPending: isTherePendingGetRequests(state),
    thereIsSomePostRequestsPending: isTherePendingPostRequests(state),
  }),
  (dispatch) => ({
    routerActions: bindActionCreators(RouterActions, dispatch),
    setTabValueAction: bindActionCreators(setTabValue, dispatch),
  }),
)(styledHeader));

