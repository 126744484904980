/**
 * AssetTools.jsx
 * Tools to manage assets
 */
import React from 'react';
import { FormattedMessage } from 'react-intl';
import {
  isSuccessfullyUploaded,
  isUploading,
  isUploadPending,
  uploadFailed,
  ASPECT_RATIOS,
} from '../Constants';
import { ReactComponent as AspectRatioHorizontalSVG } from '../assets/svg/aspectRatioHorizontal.svg';
import { ReactComponent as AspectRatioVerticalSVG } from '../assets/svg/aspectRatioVertical.svg';
import { ReactComponent as AspectRatioSquareSVG } from '../assets/svg/aspectRatioSquare.svg';

/**
 * ASSET STATUSES
 */
// const ASSET_PREVIEW_STATUS_NAME_PENDING = 'pending';
export const ASSET_PREVIEW_STATUS_NAME_READY = 'ready';
const ASSET_PREVIEW_STATUS_NAME_ERROR = 'error';
//  ASSET_PREVIEW_STATUS_PENDING = `/asset_preview_statuses/${ASSET_PREVIEW_STATUS_NAME_PENDING}`;
const ASSET_PREVIEW_STATUS_READY = `/asset_preview_statuses/${ASSET_PREVIEW_STATUS_NAME_READY}`;
const ASSET_PREVIEW_STATUS_ERROR = `/asset_preview_statuses/${ASSET_PREVIEW_STATUS_NAME_ERROR}`;

const assetPreviewFailed = (assetpreview) => {
  if ((typeof assetpreview === 'undefined')
   || (assetpreview === null)
   || (typeof assetpreview.status === 'undefined')
   || (assetpreview.status === null)) {
    return false;
  }
  return (assetpreview.status === ASSET_PREVIEW_STATUS_ERROR);
};
const assetPreviewReady = (assetpreview) => {
  if ((typeof assetpreview !== 'undefined')
   && (assetpreview !== null)
   && (typeof assetpreview.status !== 'undefined')
   && (assetpreview.status !== null)
   && (assetpreview.status === ASSET_PREVIEW_STATUS_READY)) {
    return true;
  }
  return false;
};

export const isAssetPreviewReady = (asset) => {
  return assetPreviewReady(asset.preview);
};
export const isAssetUploaded = (asset) => {
  return isSuccessfullyUploaded(asset.cloudResourceUploadStatus);
};

export const isAssetStatusOK = (asset) => {
  return (isSuccessfullyUploaded(asset.cloudResourceUploadStatus)
   && assetPreviewReady(asset.preview));
};

export const isAssetStatusNOK = (asset) => {
  return (uploadFailed(asset.cloudResourceUploadStatus)
    || assetPreviewFailed(asset.preview));
};

export const isAssetStatusUploadPending = (asset) => {
  return isUploadPending(asset.cloudResourceUploadStatus);
};

export const isAssetStatusUploading = (asset) => {
  return isUploading(asset.cloudResourceUploadStatus);
};


export const getAssetFileName = (asset) => {
  if ((typeof asset !== 'undefined')
   && asset) {
    if ((typeof asset.fileName !== 'undefined')
    && asset.fileName) {
      return asset.fileName;
    }
    // TODO - remove this field once fileName exists on back side
    if ((typeof asset.sourcePath !== 'undefined')
    && asset.sourcePath) {
      return asset.sourcePath;
    }
  }
  return null;
};

// strange function - needed while waiting for 'sourcePath' deletion
export const getAssetFileNameField = (asset) => {
  if ((typeof asset !== 'undefined')
   && asset) {
    if ((typeof asset.fileName !== 'undefined')
    && asset.fileName) {
      return 'fileName';
    }
    // TODO - remove this field once fileName exists on back side
    if ((typeof asset.sourcePath !== 'undefined')
    && asset.sourcePath) {
      return 'sourcePath';
    }
  }
  return null;
};


/**
 * List of accepted file types for autocrop
 * CAUTION : CHECK THEM ON ALL BROWSERS AS ALL BROWSERS DON'T BEHAVE THE SAME WAY
 */
export const ASSETS_FILETYPES = {
  video: [
    'video/mp4',
    'video/quicktime',
    'video/x-matroska',
  ],
};

export const getAllAcceptedFileTypes = () => {
  const acceptedList = [];
  Object.values(ASSETS_FILETYPES).forEach((assetType) => {
    assetType.forEach((mimetype) => {
      acceptedList.push(mimetype);
    });
  });

  return acceptedList;
};

// Aspect Ration labels
export const getAspectRatioIcon = (aspectRatio) => {
  let icon = <div />;
  if (aspectRatio === ASPECT_RATIOS.HORIZONTAL) {
    icon = <AspectRatioHorizontalSVG />;
  } else if (aspectRatio === ASPECT_RATIOS.VERTICAL) {
    icon = <AspectRatioVerticalSVG />;
  } else if (aspectRatio === ASPECT_RATIOS.SQUARE) {
    icon = <AspectRatioSquareSVG />;
  }
  return icon;
};// getAspectRatioMessageID

export const getDeleteCroppedAssetsConfirmationMessage = (assetsToDelete) => {
  if (assetsToDelete.length === 1) {
    return (
      <FormattedMessage
        id="medias.deleteCroppedAssetConfirmMessage"
        defaultMessage="You are about to delete the cropped version : <b>{mediaName}</b>."
        values={{
          // eslint-disable-next-line react/display-name
          b: (chunks) => (
            <span style={{ fontWeight: 'bold' }}>
              {chunks}
            </span>
          ),
          mediaName: assetsToDelete[0].name,
        }}
      />
    );
  }
  return (
    <FormattedMessage
      id="medias.deleteCroppedAssetsConfirmMessage"
      defaultMessage="You are about to delete <b>{nbMedia} cropped versions</b>."
      values={{
        // eslint-disable-next-line react/display-name
        b: (chunks) => (
          <span style={{ fontWeight: 'bold' }}>
            {chunks}
          </span>
        ),
        nbMedia: assetsToDelete.length,
      }}
    />
  );
};
