/* eslint-disable react/destructuring-assignment */
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as RouterActions from 'connected-react-router';
import { Route } from 'react-router-dom';
import CONSTANTS, { TIMEOUT_BEFORE_REDIRECT_USER } from '../Constants';
import * as AuthenticationActions from '../reducers/authenticationState';

import ROUTING from '../Routing';

function RouteComponent(props) {
  const { pathname: currentPathname } = props;

  useEffect(() => {
    autoRedirectToDefaultConnectedPage();
    autoRedirectUnLoggedUser();
  });

  // If a request failed and if error is notLoggedIn id
  // => timeout to logout user and redirect
  useEffect(() => {
    let timer;
    if (props.authError === CONSTANTS.COMMONERRORS.NOT_LOGGED_IN) {
      timer = setTimeout(() => unLoggedRequestFail(), TIMEOUT_BEFORE_REDIRECT_USER);
    }

    return () => clearTimeout(timer);
  }, [props.authError]);

  // -------------------------------------------------------------- Functions
  // Auto-redirect vers la page par defaut pour un user qui est déjà connecté
  // si on est sur une page réservée aux users non connecté (page inutile pour un user connecté)
  // ET que l'on est connecté (state.authentication.loggedIn) REDUX
  function autoRedirectToDefaultConnectedPage() {
    if (props.pathname && currentPathname && props.autoRedirectIfConnected && props.loggedIn) {
      // console.log('Redirect to connected homepage');
      redirectTo(ROUTING.DEFAULT_CONNECTED_HOMEPAGE);
    }
  }

  // Auto-redirect vers la page de LOG_IN
  // si on est sur une page réservée aux users connecté
  // ET que l'on est PAS connecté (state.authentication.loggedIn) REDUX
  function autoRedirectUnLoggedUser() {
    if (props.needAuth && !props.loggedIn) {
      // console.log('Redirect to log in page');
      redirectTo(ROUTING.LOG_IN);
    }
  }

  function unLoggedRequestFail() {
    props.authenticationActions.logout();
  }

  function redirectTo(URL) {
    props.routerActions.push(URL);
    // props.undoRedoActions.clear();
    resetStoreElements();
  }

  function resetStoreElements() {
    if (currentPathname !== props.pathname) {
      props.authenticationActions.resetError();
      // props.projectsActions.resetError();
      // props.undoRedoActions.clear();
    }
  }

  return (
    <>
      {
        props.children ? <Route {...props}>{ props.children }</Route> : <Route {...props} />
      }
    </>
  );
}


export default connect(
  state => ({
    pathname: state.router.location.pathname,
    loggedIn: state.authentication.loggedIn,
    authError: state.authentication.error,
  }),
  dispatch => ({
    routerActions: bindActionCreators(RouterActions, dispatch),
    authenticationActions: bindActionCreators(AuthenticationActions, dispatch),
    // projectsActions: bindActionCreators(ProjectsActions, dispatch),
    // undoRedoActions: bindActionCreators(UndoRedoActions, dispatch),
  }),
)(RouteComponent);
