import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { injectIntl, defineMessages } from 'react-intl';
import classNames from 'classnames';
import { makeStyles } from '@material-ui/core/styles';
import {
  SkwrButton,
  SkwrSnackBarError,
  SkwrTool,
  SkwrToolBar,
  SkwrQuestionDialog,
  ToolDeleteSVG,
} from 'skewerui';
import View from '../components/View';
import HeaderAutocrop from '../containers/Header/HeaderAutocrop';
import ResultsList from '../resultsLibrary/ResultsList';
import * as MediaLibraryActions from '../reducers/mediaLibraryActions';
import {
  getLastError,
  getCroppedAssets,
} from '../reducers/mediaLibrarySelector';

import { getAssetIRI } from '../tools/IRITools';
import { commonMessages } from '../Constants';
import { getDeleteCroppedAssetsConfirmationMessage } from '../tools/AssetTools';

const messages = defineMessages({
  deleteAssetsTooltip: {
    id: 'results.deleteAssetsTooltip',
    defaultMessage: 'Delete selected media',
  },
  downloadButton: {
    id: 'results.downloadButton',
    defaultMessage: 'Download',
  },
  deleteCroppedAssetsConfirmTitle: {
    id: 'medias.deleteCroppedAssetsConfirmTitle',
    defaultMessage: 'Delete Cropped Media ?',
  },
  deleteCroppedAssetsAnswerButton: {
    id: 'medias.deleteCroppedAssetsAnswerButton',
    defaultMessage: 'Delete media',
  },
});

const useStyles = makeStyles(() => ({
  croppedMediaContainer: {
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    verticalAlign: 'middle',
    flexDirection: 'column',
    position: 'relative',
  },
  croppedMediaHeader: {
    width: '100%',
    paddingLeft: '20px',
    paddingRight: '20px',
    marginRight: '20px',
    display: 'flex',
    flexDirection: 'row-reverse',
    padding: '10px',
    flex: '0',
  },
  croppedMediaHeaderCenter: {
    flex: 1,
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'flex-start',
  },
  croppedMediaDownloadToolbar: {
    flex: '0',
    padding: '10px',
  },
  croppedMediaToolbar: {
  },
  croppedMediaToolbarDisabled: {
    visibility: 'hidden',
  },
  croppedMediaAssets: {
    width: '100%',
    padding: '5px',
    boxSizing: 'border-box',
    outline: 'none',
    flex: '1',
    overflow: 'scroll',
  },
}));

const Results = (props) => {
  const {
    intl: { formatMessage },
    croppedAssets,
    lastError,
    mediaLibraryActions,
  } = props;

  const classes = useStyles();
  const [selectedCroppedAssets, setSelectedCroppedAssets] = useState([]);

  const [askAQuestion, setAskAQuestion] = useState(false);
  const [questionDialogValues, setQuestionDialogValues] = useState({});

  const [openErrorSnackbar, setOpenErrorSnackbar] = useState(false);

  useEffect(() => {
    if (lastError) {
      setOpenErrorSnackbar(true);
    } else {
      setOpenErrorSnackbar(false);
    }
  }, [lastError]);

  useEffect(() => {
    mediaLibraryActions.retrieveCroppedAssets();
  }, []);
  useEffect(() => {
    setSelectedCroppedAssets([]);
  }, [croppedAssets]);

  const getErrorMessage = () => {
    if ((typeof lastError === 'undefined') || (lastError === null)) {
      return '';
    }
    const errorKey = lastError;

    let errorMessage = '';
    if (typeof commonMessages[errorKey] !== 'undefined') {
      errorMessage = formatMessage(commonMessages[errorKey]);
    } else {
      const param = { internalErrorCode: errorKey };
      errorMessage = formatMessage(commonMessages.internalError, param);
    }
    return errorMessage;
  };// getErrorMessage
  const handleOnCloseErrorSnackBar = () => {
    mediaLibraryActions.resetError();
  };

  const onMediaSelectionChanged = (media) => {
    const newSelectedAssets = [...selectedCroppedAssets];

    const idx = newSelectedAssets.findIndex((f) => (getAssetIRI(f) === getAssetIRI(media)));
    if (idx !== -1) {
      // The media was selected, remove it
      newSelectedAssets.splice(idx, 1);
    } else {
      newSelectedAssets.push(media);
    }
    setSelectedCroppedAssets(newSelectedAssets);
  };

  const handleCheckAllAssets = (ckecked) => {
    let newSelectedAssets = [];
    if (ckecked) {
      newSelectedAssets = [...croppedAssets];
    }
    setSelectedCroppedAssets(newSelectedAssets);
  };

  const handleDeleteAssets = () => {
    if (selectedCroppedAssets.length !== 0) {
      setQuestionDialogValues({
        title: formatMessage(messages.deleteCroppedAssetsConfirmTitle),
        question: getDeleteCroppedAssetsConfirmationMessage(selectedCroppedAssets),
        btnYes: formatMessage(messages.deleteCroppedAssetsAnswerButton),
        btnNo: null,
        btnCancel: formatMessage(commonMessages.cancelButton),
        onYes: () => { deleteCroppedAssets(selectedCroppedAssets); },
        onNo: null,
      });
      setAskAQuestion(true);
    }
  };
  const deleteCroppedAssets = (croppedAssetsToDelete) => {
    mediaLibraryActions.deleteCroppedAssets(croppedAssetsToDelete);
  };

  const handleClickOnDownloadItem = (assetToDownload) => {
    downloadAssets([assetToDownload]);
  };
  const handleClickOnDownloadTool = () => {
    downloadAssets(selectedCroppedAssets);
  };
  const downloadAssets = (assetsToDownload) => {
    assetsToDownload.forEach((media) => {
      mediaLibraryActions.startDownload(media);
    });
  };

  const renderCroppedMediaHeader = () => {
    const disabledToolbar = (selectedCroppedAssets.length === 0);
    const toolbarClass = classNames(
      classes.croppedMediaToolbar,
      disabledToolbar ? classes.croppedMediaToolbarDisabled : null,
    );
    return (
      <div className={classes.croppedMediaHeader}>
        {/* <div
          className={classes.croppedMediaHeaderCenter}
        /> */}
        {/* {selectedCroppedAssets.length !== 0 && ( */}
        <SkwrToolBar className={toolbarClass}>
          <SkwrTool
            SVGIcon={ToolDeleteSVG}
            tooltip={formatMessage(messages.deleteAssetsTooltip)}
            onClick={handleDeleteAssets}
            disabled={disabledToolbar}
          />
        </SkwrToolBar>
        {/* )} */}
      </div>
    );
  };// renderCroppedMediaHeader

  return (
    <View
      loggedIn="true"
      pageConnectedUser
      appDedicatedHeader={(
        <HeaderAutocrop />
      )}
    >
      {askAQuestion && (
        <SkwrQuestionDialog
          isOpen={askAQuestion}
          titleDialog={questionDialogValues.title}
          textDialog={questionDialogValues.question}
          textBtnYes={questionDialogValues.btnYes}
          textBtnNo={questionDialogValues.btnNo}
          textBtnCancel={questionDialogValues.btnCancel}
          actionBtnNo={questionDialogValues.onNo}
          actionBtnYes={questionDialogValues.onYes}
          actionBtnCancel={() => setAskAQuestion(false)}
        />
      )}

      <div className={classes.croppedMediaContainer}>
        {renderCroppedMediaHeader()}
        <ResultsList
          croppedAssets={croppedAssets}
          selectedAssets={selectedCroppedAssets}
          onCheckOne={onMediaSelectionChanged}
          onCheckAll={handleCheckAllAssets}
          onDelete={handleDeleteAssets}
          onDownload={handleClickOnDownloadItem}
        />
        <div className={classes.croppedMediaDownloadToolbar}>
          <SkwrButton
            disabled={selectedCroppedAssets.length === 0}
            onClick={handleClickOnDownloadTool}
          >
            {formatMessage(messages.downloadButton)}
          </SkwrButton>
        </div>

      </div>
      <SkwrSnackBarError
        message={getErrorMessage()}
        toggleError={openErrorSnackbar}
        onClose={handleOnCloseErrorSnackBar}
      />
    </View>
  );
};

export default connect(
  (state) => ({
    lastError: getLastError(state),
    croppedAssets: getCroppedAssets(state),
  }),
  (dispatch) => ({
    mediaLibraryActions: bindActionCreators(MediaLibraryActions, dispatch),
  }),
)(injectIntl(Results));
