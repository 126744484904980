import React, { lazy } from 'react';
import { Switch } from 'react-router-dom';
import { getSuspensedComponent, SkwrSuspense } from 'skewerui';
import Route from './containers/Route';
import ROUTING from './Routing';

import Index from './pages/Index';
import Login from './pages/Login';
import Medias from './pages/Medias';
import Results from './pages/Results';
// import Error404 from './pages/Error404';

const Error404 = (lazy(() => (import('./pages/Error404'))));


export default (() => (
  <SkwrSuspense>
    <Switch>
      <Route
        exact
        path={ROUTING.ROOT}
        component={Index}
        autoRedirectIfConnected
        needAuth={false}
      />
      <Route
        exact
        path={ROUTING.LOG_IN}
        component={Login}
        autoRedirectIfConnected
        needAuth={false}
      />
      <Route
        exact
        path={ROUTING.MEDIAS}
        needAuth
        component={Medias}
      />
      <Route
        exact
        path={ROUTING.RESULTS}
        needAuth
        component={Results}
      />

      <Route component={getSuspensedComponent(Error404)} />
    </Switch>
  </SkwrSuspense>
))();

