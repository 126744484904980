/**
 * File : mediaLibraryActions.js
 * Actions and state to manage assets
 */
import * as MediaLibrary from '../helpers/mediaLibrary';
import { UPLOAD_STATUS } from '../Constants';
import PENDING_REQUESTS_ACTIONS from './pendingRequestsStateConstants';
import ACTIONS from './mediaLibraryStateConstants';
import {
  getCurrentUser,
} from './authenticationSelector';
import {
  getAssetFromIRI,
} from './mediaLibrarySelector';
import {
  getAssetIRI,
} from '../tools/IRITools';
import {
  ASSET_PREVIEW_STATUS_NAME_READY,
} from '../tools/AssetTools';
import { getFileToUploadFromIndex } from './mediaLibraryState';


export const resetError = () => {
  return {
    type: ACTIONS.CLEAR_ERROR,
  };
};


/**
 * Change the asset name
 * @param {*} node Asset of which we will change the name
 * @param {*} name the new asset name
 */
export const changeAssetName = (asset, name) => (dispatch, getState) => {
  dispatch({ type: ACTIONS.CLEAR_ERROR });

  dispatch({
    type: ACTIONS.UPDATE_ASSET_REQUEST,
    payload: 'waiting response from API ...',
  });

  MediaLibrary.updateAsset(asset, { name })
    .then((updatedAsset) => {
      dispatch({
        type: ACTIONS.UPDATE_ASSET_SUCCESS,
        asset: updatedAsset,
      });
      dispatch({
        type: ACTIONS.UPDATE_ASSET_NAME_SUCCESS,
        asset,
        oldName: asset.name,
        newName: updatedAsset.name,
      });
    })
    .catch((error) => {
      dispatch({
        type: ACTIONS.UPDATE_ASSET_FAILURE,
        error: error.message,
      });
    });
};


export const retrieveAssets = () => {
  return (dispatch, getState) => {
    const currentUser = getCurrentUser(getState());
    if (currentUser === null) {
      console.log('XXX Try to find why we get there !! Null User');
      return;
    }

    dispatch({
      type: ACTIONS.GET_ASSETS_REQUEST,
    });

    MediaLibrary.getAssets(currentUser)
      .then((assetsData) => {
        dispatch({
          type: ACTIONS.GET_ASSETS_SUCCESS,
          assetsData,
        });
      }).catch((error) => {
        dispatch({
          type: ACTIONS.GET_ASSETS_FAILURE,
          error: error.message,
        });
      });
  };
};

export const retrieveCroppedAssets = () => {
  return (dispatch, getState) => {
    const currentUser = getCurrentUser(getState());
    if (currentUser === null) {
      console.log('XXX Try to find why we get there !! Null User');
      return;
    }

    dispatch({
      type: ACTIONS.GET_CROPPED_ASSETS_REQUEST,
    });

    MediaLibrary.getCroppedAssets(currentUser)
      .then((assetsData) => {
        dispatch({
          type: ACTIONS.GET_CROPPED_ASSETS_SUCCESS,
          assetsData,
        });
      }).catch((error) => {
        dispatch({
          type: ACTIONS.GET_CROPPED_ASSETS_FAILURE,
          error: error.message,
        });
      });
  };
};

/**
 * Get asset from assetIRI
 * @param {*} assetUuid assetIRI
 */
export const getAsset = assetIRI => (dispatch, getState) => {
  dispatch({
    type: ACTIONS.GET_ASSET_REQUEST,
  });

  MediaLibrary.getAsset(assetIRI)
    .then((asset) => {
      dispatch({
        type: ACTIONS.GET_ASSET_SUCCESS,
        asset,
      });
    }).catch((error) => {
      dispatch({
        type: ACTIONS.GET_ASSET_FAILURE,
        error: error.message,
      });
    });
};

/**
 * Uploads an array of assets
 * @param {*} assetsToUpload array of assets to upload
 */
export const addAssetsToUploadQueue = (
  assetsToUpload,
  endOfCreationCallback,
) => (dispatch, getState) => {
  // If there is no file given, DO NOTHING !
  if ((typeof assetsToUpload === 'undefined')
   || (assetsToUpload === null)
   || (assetsToUpload.length === 0)) {
    console.error('There is no file to upload !');
    return;
  }

  const {
    creationProgress,
  } = getState().mediaLibrary;

  // Check if the asset creation loop is already running or not
  let isAlreadyRunning = false;
  if ((creationProgress.toCreate.length !== 0)
   || (creationProgress.creationInProgress.length !== 0)) {
    isAlreadyRunning = true;
  }

  dispatch({
    type: ACTIONS.ADD_FILES_TO_CREATION_QUEUE,
    files: assetsToUpload,
    endOfCreationCallback,
  });

  // If the loop was not already running, start it !
  if (!isAlreadyRunning) {
    // dispatch a global post request to ensure that the user
    // will not be able to quit between to creation or upload actions
    dispatch({
      type: PENDING_REQUESTS_ACTIONS.ADD_POST_REQUEST,
    });

    dispatch(loopToCreateAssets());
  }
};// addAssetsToUploadQueue

/**
 * Loop to create Assets
 * Creates firt asset in the creation pending queue
 */
export const loopToCreateAssets = () => (dispatch, getState) => {
  const {
    creationProgress,
    uploadProgress,
  } = getState().mediaLibrary;

  // Check if we are at the end of the creation queue
  if (creationProgress.toCreate.length === 0) {
    // RAF NOTHING TO DO ANYMORE ?!
    // DISPATCH END OF THE LOOP EVENT !!
    dispatch({
      type: ACTIONS.END_ASSET_CREATION_LOOP,
    });
    return;
  }

  createAssetForFile(creationProgress.toCreate[0]);

  function createAssetForFile(currentFileData) {
    const currentFileIdx = currentFileData.idxInUpload;
    const currentFile = getFileToUploadFromIndex(currentFileIdx);
    if (currentFile === null) {
      console.debug('createAssetForFile : Unexpected error : file index out of range');
      return;
    }

    // WARNING !!
    // On react-dropzone page :
    // Warning: On most recent browsers versions, the files given by onDrop won't have
    // properties path or fullPath
    // For security reasons (sic.)
    if (typeof currentFile.path !== 'undefined') {
      console.log('Try to create asset for file :  ', currentFile.path);
    } else {
      console.log('Try to create asset for file :  ', currentFile);
    }

    dispatch({
      type: ACTIONS.START_ASSET_CREATION,
      fileIdxInUploadArray: currentFileIdx,
    });

    dispatch({
      type: ACTIONS.NEW_ASSET_REQUEST,
    });

    const currentUser = getCurrentUser(getState());

    const assetToCreate = {
      name: currentFile.name,
      sourcePath: currentFile.name, // TODO - remove this field once fileName exists on back side
      fileName: currentFile.name,
      userUuid: currentUser.uuid,
    };

    // create
    MediaLibrary.createAsset(assetToCreate).then((newlyCreatedAsset) => {
      dispatch({
        type: ACTIONS.NEW_ASSET_SUCCESS,
        payload: newlyCreatedAsset,
      });

      dispatch({
        type: ACTIONS.END_ASSET_CREATION,
        fileIdxInUploadArray: currentFileIdx,
        asset: newlyCreatedAsset,
      });

      if ((typeof currentFileData.endOfCreationCallback !== 'undefined')
      && (currentFileData.endOfCreationCallback !== null)) {
        currentFileData.endOfCreationCallback(null);
      }

      dispatch(loopToCreateAssets());

      // Should we start the upload loop ?
      let isUploadAlreadyRunning = false;
      if (uploadProgress.uploadInProgress.length !== 0) {
        isUploadAlreadyRunning = true;
      }
      if (!isUploadAlreadyRunning) {
        // Start the upload loop
        dispatch(loopToUploadAssets());
      }
    }).catch((error) => {
      dispatch({
        type: ACTIONS.NEW_ASSET_FAILURE,
        error,
      });

      dispatch({
        type: ACTIONS.END_ASSET_CREATION,
        fileIdxInUploadArray: currentFileIdx,
        asset: null,
      });

      dispatch(loopToCreateAssets());

      // Should we start the upload loop ?
      let isUploadAlreadyRunning = false;
      if (uploadProgress.uploadInProgress.length !== 0) {
        isUploadAlreadyRunning = true;
      }
      if (!isUploadAlreadyRunning) {
        // Start the upload loop
        dispatch(loopToUploadAssets());
      }
    });
  } // createAssetForFile
};// loopToCreateAssets

/**
 * Loop to upload Assets
 * Upload firt asset in the upload pending queue
 */
export const loopToUploadAssets = () => (dispatch, getState) => {
  const {
    uploadProgress,
    creationProgress,
  } = getState().mediaLibrary;

  // Check if we are at the end of the upload queue
  if (uploadProgress.toUpload.length === 0) {
    // RAF NOTHING TO DO ANYMORE ?!
    if ((creationProgress.toCreate.length !== 0)
     || (creationProgress.creationInProgress.length !== 0)) {
      // This case can arrive if the upload loop goes quicker
      // than the creation loop
      console.debug('End of upload loop, but creation still in progress');
    } else {
      console.debug('End of creation and upload loops');

      // DISPATCH END OF THE LOOP EVENT !!
      dispatch({
        type: ACTIONS.END_ASSET_UPLOAD_LOOP,
      });
      dispatch({
        type: PENDING_REQUESTS_ACTIONS.REMOVE_POST_REQUEST,
      });
    }

    return;
  }

  uploadAssetFile(
    uploadProgress.toUpload[0].fileIdxInUploadArray,
    uploadProgress.toUpload[0].asset,
  );

  function uploadAssetFile(fileToUploadIdx, newlyCreatedAsset) {
    const fileToUpload = getFileToUploadFromIndex(fileToUploadIdx);
    if (fileToUpload === null) {
      console.debug('uploadAssetFile : Unexpected error : file index out of range');
      return;
    }

    dispatch({
      type: ACTIONS.START_ASSET_UPLOAD,
      assetFile: {
        fileIdxInUploadArray: fileToUploadIdx,
        asset: newlyCreatedAsset,
      },
    });

    if (newlyCreatedAsset === null) {
      // Case when an error occured during the creation of the asset
      dispatch({
        type: ACTIONS.END_ASSET_UPLOAD,
        assetFile: {
          fileIdxInUploadArray: fileToUploadIdx,
          asset: newlyCreatedAsset,
        },
      });

      // OK upload fails, but what ?! Continue to upload the other files
      // Continue the upload loop - Upload next asset file
      dispatch(loopToUploadAssets());

      return;
    }


    // Upload Asset
    if (typeof fileToUpload.path !== 'undefined') {
      console.log('Try to upload file :  ', fileToUpload.path);
    } else {
      console.log('Try to upload file :  ', fileToUpload);
    }
    dispatch(changeAssetUploadStatus(newlyCreatedAsset, UPLOAD_STATUS.START));
    MediaLibrary.uploadAsset(newlyCreatedAsset, fileToUpload).then((data) => {
      dispatch(changeAssetUploadStatus(newlyCreatedAsset, UPLOAD_STATUS.SUCCESS));

      // Is the transcode loop running ?
      const {
        transcodeProgress,
      } = getState().mediaLibrary;
      const transcodeAllreadyRunning = (transcodeProgress.transcodeInProgress.length !== 0);

      dispatch({
        type: ACTIONS.END_ASSET_UPLOAD,
        assetFile: {
          fileIdxInUploadArray: fileToUploadIdx,
          asset: newlyCreatedAsset,
        },
      });

      // Continue the upload loop - Upload next asset file
      dispatch(loopToUploadAssets());

      // start a loop to wait for preview transcodings
      // XXX TODO - uncomment when thumbnails will be ready
      // if (!transcodeAllreadyRunning) {
      //   dispatch(loopToWaitForTranscodedAssets());
      // }
    }).catch((error) => {
      dispatch(changeAssetUploadStatus(newlyCreatedAsset, UPLOAD_STATUS.ERROR));

      console.error(`Unable to upload file '${fileToUpload.name}' : ${error}`);

      dispatch({
        type: ACTIONS.END_ASSET_UPLOAD,
        assetFile: {
          fileIdxInUploadArray: fileToUploadIdx,
          asset: newlyCreatedAsset,
        },
      });

      // OK upload fails, but what ?! Continue to upload the other files
      // Continue the upload loop - Upload next asset file
      dispatch(loopToUploadAssets());
    });
  } // uploadAssetFiles
};// loopToUploadAssets

export const loopToWaitForTranscodedAssets = () => (dispatch, getState) => {
  const {
    transcodeProgress,
  } = getState().mediaLibrary;

  if (transcodeProgress.transcodeInProgress.length === 0) {
    // no need to wait anymore
    return;
  }

  // get the creationDate of first asset in the waiting array
  let waitingAsset;
  for (let i = 0;
    (i < transcodeProgress.transcodeInProgress.length) && (typeof waitingAsset === 'undefined');
    i += 1) {
    waitingAsset = getAssetFromIRI(getState(), transcodeProgress.transcodeInProgress[i]);
  }

  if (typeof waitingAsset === 'undefined') {
    // no need to wait anymore - may be the assets are already deleted
    return;
  }

  dispatch({
    type: ACTIONS.GET_ASSETS_REQUEST,
  });

  const filterParams = {};
  filterParams['createdAt[after]'] = waitingAsset.createdAt;
  filterParams['preview.status.name'] = ASSET_PREVIEW_STATUS_NAME_READY;

  const currentUser = getCurrentUser(getState());

  MediaLibrary.getAssets(currentUser, filterParams)
    .then((assetsData) => {

      dispatch({
        type: ACTIONS.GET_ASSETS_SUCCESS,
        assetsData,
      });

      // Loop every n seconds
      // set a timeout to prevent server to feel attacked by too much requests
      setTimeout(() => { dispatch(loopToWaitForTranscodedAssets()); }, 1500);
    }).catch((error) => {
      dispatch({
        type: ACTIONS.GET_ASSETS_FAILURE,
        error: error.message,
      });
    });
}; // loopToWaitForTranscodedAssets


const changeAssetUploadStatus = (asset, uploadStatus) => (dispatch, getState) => {
  dispatch({ type: ACTIONS.CLEAR_ERROR });

  dispatch({
    type: ACTIONS.UPDATE_ASSET_REQUEST,
    payload: 'waiting response from API ...',
  });

  const modifiedAsset = {
    cloudResourceUploadStatus: uploadStatus,
  };

  MediaLibrary.updateAsset(asset, modifiedAsset)
    .then((assetData) => {
      dispatch({
        type: ACTIONS.UPDATE_ASSET_SUCCESS,
        asset: assetData,
      });
    })
    .catch((error) => {
      dispatch({
        type: ACTIONS.UPDATE_ASSET_FAILURE,
        error: error.message,
      });
      dispatch(getAsset(getAssetIRI(asset)));
    });
};


/**
 * Delete an array of assets
 */
export const deleteAssets = assets => (dispatch, getState) => {
  dispatch({ type: ACTIONS.CLEAR_ERROR });

  dispatch({
    type: ACTIONS.DELETE_ASSETS_REQUEST,
    assets,
  });

  const {
    promises,
  } = getDeleteAssetsPromises(assets);

  Promise.all(promises).then((response) => {
    dispatch({
      type: ACTIONS.DELETE_ASSETS_SUCCESS,
      deletedAssets: assets,
    });
  }).catch((error) => {
    dispatch({
      type: ACTIONS.DELETE_ASSETS_FAILURE,
      error: error.message,
    });
  });
};

function getDeleteAssetsPromises(assets) {
  const promises = [];

  assets.forEach((asset) => {
    promises.push(MediaLibrary.deleteAsset(asset));
  });

  return {
    promises,
  };
}// getDeleteAssetsPromises

/**
 * Delete an array of cropped assets
 */
export const deleteCroppedAssets = assets => (dispatch, getState) => {
  dispatch({ type: ACTIONS.CLEAR_ERROR });

  dispatch({
    type: ACTIONS.DELETE_CROPPED_ASSETS_REQUEST,
    assets,
  });

  const {
    promises,
  } = getDeleteCroppedAssetsPromises(assets);

  Promise.all(promises).then((response) => {
    dispatch({
      type: ACTIONS.DELETE_CROPPED_ASSETS_SUCCESS,
      deletedAssets: assets,
    });
  }).catch((error) => {
    dispatch({
      type: ACTIONS.DELETE_CROPPED_ASSETS_FAILURE,
      error: error.message,
    });
  });
};// deleteCroppedAssets

function getDeleteCroppedAssetsPromises(assets) {
  const promises = [];

  assets.forEach((asset) => {
    promises.push(MediaLibrary.deleteCroppedAsset(asset));
  });

  return {
    promises,
  };
}// getDeleteCroppedAssetsPromises

/**
 * Starts a crop job for given asset with give aspect ratio
 */
export const startCropJob = (asset, aspect) => (dispatch) => {
  dispatch({ type: ACTIONS.CLEAR_ERROR });

  dispatch({
    type: ACTIONS.START_CROP_ASSET_JOB_REQUEST,
    asset,
  });

  MediaLibrary.startCropJob(asset, aspect).then(() => {
    dispatch({
      type: ACTIONS.START_CROP_ASSET_JOB_SUCCESS,
      asset,
      aspectRatio: aspect,
    });
  }).catch((error) => {
    dispatch({
      type: ACTIONS.START_CROP_ASSET_JOB_FAILURE,
      asset,
      error: error.message,
    });
  });
};

/**
 * Start the download of a given cropped asset
 */
export const startDownload = (croppedAsset) => (dispatch, getState) => {
  dispatch({
    type: ACTIONS.START_DOWNLOAD_REQUEST,
    payload: 'waiting response from API ...',
  });

  MediaLibrary.getDownloadUrl(croppedAsset)
    .then((response) => {
      const url = response;

      // XXX A revoir !! CF Editor download exports
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', croppedAsset.name);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      // XXX -> window.open(url, '_self'); // download the content uf url

      dispatch({
        type: ACTIONS.START_DOWNLOAD_SUCCESS,
        status: ACTIONS.START_DOWNLOAD_SUCCESS,
        asset: croppedAsset,
        url,
      });
    }).catch((error) => {
      dispatch({
        type: ACTIONS.START_DOWNLOAD_FAILURE,
        error: error.message,
      });
    });
};
