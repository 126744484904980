/**
 * File : mediaLibraryStateConstants.js
 */
const ACTIONS = {
  CLEAR_ERROR: 'MEDIALIB_CLEAR_ERROR',

  NEW_ASSET_REQUEST: 'NEW_ASSET_API_POST_REQUEST',
  NEW_ASSET_SUCCESS: 'NEW_ASSET_API_POST_SUCCESS',
  NEW_ASSET_FAILURE: 'NEW_ASSET_API_POST_FAILURE',

  DELETE_ASSETS_REQUEST: 'DELETE_ASSETS_API_POST_REQUEST',
  DELETE_ASSETS_SUCCESS: 'DELETE_ASSETS_API_POST_SUCCESS',
  DELETE_ASSETS_FAILURE: 'DELETE_ASSETS_API_POST_FAILURE',

  GET_ASSETS_REQUEST: 'GET_ASSETS_API_GET_REQUEST',
  GET_ASSETS_SUCCESS: 'GET_ASSETS_API_GET_SUCCESS',
  GET_ASSETS_FAILURE: 'GET_ASSETS_API_GET_FAILURE',

  GET_ASSET_REQUEST: 'GET_ASSET_API_GET_REQUEST',
  GET_ASSET_SUCCESS: 'GET_ASSET_API_GET_SUCCESS',
  GET_ASSET_FAILURE: 'GET_ASSET_API_GET_FAILURE',

  UPDATE_ASSET_REQUEST: 'UPDATE_ASSET_API_POST_REQUEST',
  UPDATE_ASSET_SUCCESS: 'UPDATE_ASSET_API_POST_SUCCESS',
  UPDATE_ASSET_NAME_SUCCESS: 'UPDATE_ASSET_NAME_SUCCESS',
  UPDATE_ASSET_FAILURE: 'UPDATE_ASSET_API_POST_FAILURE',

  GET_CROPPED_ASSETS_REQUEST: 'GET_CROPPED_ASSETS_API_GET_REQUEST',
  GET_CROPPED_ASSETS_SUCCESS: 'GET_CROPPED_ASSETS_API_GET_SUCCESS',
  GET_CROPPED_ASSETS_FAILURE: 'GET_CROPPED_ASSETS_API_GET_FAILURE',

  DELETE_CROPPED_ASSETS_REQUEST: 'DELETE_CROPPED_ASSETS_API_POST_REQUEST',
  DELETE_CROPPED_ASSETS_SUCCESS: 'DELETE_CROPPED_ASSETS_API_POST_SUCCESS',
  DELETE_CROPPED_ASSETS_FAILURE: 'DELETE_CROPPED_ASSETS_API_POST_FAILURE',

  START_CROP_ASSET_JOB_REQUEST: 'START_CROP_ASSET_JOB_API_POST_REQUEST',
  START_CROP_ASSET_JOB_SUCCESS: 'START_CROP_ASSET_JOB_API_POST_SUCCESS',
  START_CROP_ASSET_JOB_FAILURE: 'START_CROP_ASSET_JOB_API_POST_FAILURE',

  START_DOWNLOAD_REQUEST: 'START_DOWNLOAD_API_GET_REQUEST',
  START_DOWNLOAD_SUCCESS: 'START_DOWNLOAD_API_GET_SUCCESS',
  START_DOWNLOAD_FAILURE: 'START_DOWNLOAD_API_GET_FAILURE',

  // PROGRESS ACTIONS
  ADD_FILES_TO_CREATION_QUEUE: 'ADD_FILES_TO_CREATION_QUEUE',

  START_ASSET_CREATION: 'START_ASSET_CREATION',
  END_ASSET_CREATION: 'END_ASSET_CREATION',

  END_ASSET_CREATION_LOOP: 'END_ASSET_CREATION_LOOP',

  START_ASSET_UPLOAD: 'START_ASSET_UPLOAD',
  END_ASSET_UPLOAD: 'END_ASSET_UPLOAD',

  END_ASSET_UPLOAD_LOOP: 'END_ASSET_UPLOAD_LOOP',
};
export default ACTIONS;
