import CONSTANTS from '../Constants';

/**
 * Returns the IRI of a given connectable object
 * @param {*} connectable connectable we want the IRI
 */
export const getObjectIRI = obj => (obj ? obj[CONSTANTS.IRI_FIELD] : null);
export const getAssetIRI = getObjectIRI;

/**
 * IRI setters
 * @param {} iri IRI to set
 */
// eslint-disable-next-line no-param-reassign
export const setObjectIRI = (obj, iri) => { obj[CONSTANTS.IRI_FIELD] = iri; };
export const setAssetIRI = setObjectIRI;

/**
 * Returns the UUID of an asset given its IRI
 * @param {*} assetIRI IRI of the asset
 */
export const assetUuidFromIRI = (assetIRI) => {
  const assetsIRIPrefix = '/'.concat(CONSTANTS.IRI.ASSETS);
  return assetIRI.slice(assetsIRIPrefix.length);
};
