import * as User from '../helpers/user';
import ACTIONS from './authenticationStateConstants';

const initialState = {
  loggedIn: false,
  user: null,
  status: null,
  error: null,
};

export const setSignup = form => (dispatch) => {
  dispatch({
    type: ACTIONS.SIGNUP_REQUEST,
    payload: 'waiting response from api ...',
  });

  User.signUp({
    email: form.email,
    password: form.password,
    username: form.username,
  })
    .then((username) => {
      dispatch({
        type: ACTIONS.SIGNUP_SUCCESS,
        payload: username,
      });
    })
    .catch((error) => {
      dispatch({
        type: ACTIONS.SIGNUP_FAILURE,
        error: error.message,
      });
    });
};

export const login = formValues => (dispatch) => {
  dispatch({
    type: ACTIONS.LOGIN_REQUEST,
    payload: 'waiting response from api ...',
  });
  User.login(formValues)
    .then((user) => {
      dispatch({
        type: ACTIONS.LOGIN_SUCCESS,
        user,
      });
    })
    .catch((error) => {
      dispatch({
        type: ACTIONS.LOGIN_FAILURE,
        error: error.message,
      });
    });
};

export const resetError = () => {
  return {
    type: ACTIONS.CLEAR_ERROR,
  };
};

export const logout = () => {
  return (dispatch) => {
    User.logout().then((user) => {
      dispatch({
        type: ACTIONS.LOGOUT,
      });
    });
  };
};

export const setForgotPassword = formValues => (dispatch) => {
  dispatch({
    type: ACTIONS.FORGOT_PASSWORD_REQUEST,
  });

  User.forgotPassword(formValues.email)
    .then((user) => {
      dispatch({
        type: ACTIONS.FORGOT_PASSWORD_SUCCESS,
      });
    })
    .catch((error) => {
      dispatch({
        type: ACTIONS.FORGOT_PASSWORD_FAILURE,
        error: error.message,
      });
    });
};

export const setResetPassword = (formValues, forgottenPasswordKey) => (dispatch) => {
  dispatch({
    type: ACTIONS.RESET_PASSWORD_REQUEST,
  });

  const newpass = {
    password: formValues.password,
    passwordConfirmation: formValues.passwordconfirm,
  };

  User.resetPassword(newpass, forgottenPasswordKey)
    .then((user) => {
      dispatch({
        type: ACTIONS.RESET_PASSWORD_SUCCESS,
      });
    })
    .catch((error) => {
      dispatch({
        type: ACTIONS.RESET_PASSWORD_FAILURE,
        error: error.message,
      });
    });
};

export const validateUser = userid => (dispatch) => {
  dispatch({
    type: ACTIONS.VALIDATE_USER_REQUEST,
  });

  User.validateUser(userid)
    .then((user) => {
      dispatch({
        type: ACTIONS.VALIDATE_USER_SUCCESS,
      });
    })
    .catch((error) => {
      dispatch({
        type: ACTIONS.VALIDATE_USER_FAILURE,
        error: error.message,
      });
    });
};

export const loadUser = () => {
  return (dispatch) => {
    dispatch({
      type: ACTIONS.CHECKTOKEN_REQUEST,
      payload: 'waiting response from api ...',
    });

    User.loadData();
    User.checkToken()
      .then(() => {
        dispatch({
          type: ACTIONS.CHECKTOKEN_SUCCESS,
          userStorage: User.get(),
        });
      })
      .catch((error) => {
        dispatch({
          type: ACTIONS.CHECKTOKEN_FAILURE,
        });
      });
  };
};

export const updateUserSettings = formValues => (dispatch) => {
  dispatch(resetError());

  dispatch({
    type: ACTIONS.UPDATE_USERSETTINGS_REQUEST,
    payload: 'waiting response from API ...',
  });

  const settingsToSet = { ...formValues };

  // Remove email if it is given, as it cant be modified thru this request
  if ((typeof settingsToSet.email !== 'undefined')
   && (settingsToSet.email !== null)) {
    delete settingsToSet.email;
  }

  User.updateUserSettings(settingsToSet)
    .then(() => {
      dispatch({
        type: ACTIONS.UPDATE_USERSETTINGS_SUCCESS,
        user: User.get(),
      });
    })
    .catch((error) => {
      dispatch({
        type: ACTIONS.UPDATE_USERSETTINGS_FAILURE,
        error: error.message,
      });
    });
};

export default (state = initialState, action) => {
  switch (action.type) {
    // Huge case here to catch FALIURE type and update logged status if necessary
    // if request fail with status 401 (bad JWT token) -> we must loggout user

    case ACTIONS.CLEAR_ERROR:
      return {
        ...state,
        error: null,
        status: null,
      };

    case ACTIONS.LOGIN_REQUEST:
      return {
        ...initialState,
        status: ACTIONS.LOGIN_REQUEST,
      };

    case ACTIONS.LOGIN_SUCCESS:
      return {
        ...initialState,
        loggedIn: true,
        user: action.user,
        error: null,
      };

    case ACTIONS.LOGIN_FAILURE:
      return {
        ...initialState,
        error: action.error,
        status: ACTIONS.LOGIN_FAILURE,
      };

    case ACTIONS.LOGOUT:
      return {
        loggedIn: false,
        user: null,
      };

    case ACTIONS.CHECKTOKEN_REQUEST:
      return state;

    case ACTIONS.CHECKTOKEN_SUCCESS:
      return {
        loggedIn: true,
        user: action.userStorage,
      };

    case ACTIONS.CHECKTOKEN_FAILURE:
      return {
        ...state,
        loggedIn: false,
        user: null,
        status: ACTIONS.CHECKTOKEN_FAILURE,
      };

    case ACTIONS.FORGOT_PASSWORD_SUCCESS:
      return {
        ...state,
        status: ACTIONS.FORGOT_PASSWORD_SUCCESS,
      };

    case ACTIONS.FORGOT_PASSWORD_FAILURE:
      return {
        ...state,
        error: action.error,
        status: ACTIONS.FORGOT_PASSWORD_FAILURE,
      };

    case ACTIONS.FORGOT_PASSWORD_REQUEST:
      return {
        ...state,
        status: ACTIONS.FORGOT_PASSWORD_REQUEST,
      };

    case ACTIONS.RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        status: ACTIONS.RESET_PASSWORD_SUCCESS,
      };

    case ACTIONS.RESET_PASSWORD_FAILURE:
      return {
        ...state,
        error: action.error,
        status: ACTIONS.RESET_PASSWORD_FAILURE,
      };

    case ACTIONS.RESET_PASSWORD_REQUEST:
      return {
        ...state,
        status: ACTIONS.RESET_PASSWORD_REQUEST,
      };

    case ACTIONS.VALIDATE_USER_SUCCESS:
      return {
        ...state,
        status: ACTIONS.VALIDATE_USER_SUCCESS,
      };

    case ACTIONS.VALIDATE_USER_FAILURE:
      return {
        ...state,
        error: action.error,
        status: ACTIONS.VALIDATE_USER_FAILURE,
      };

    case ACTIONS.VALIDATE_USER_REQUEST:
      return {
        ...state,
        status: ACTIONS.VALIDATE_USER_REQUEST,
      };

    case ACTIONS.SIGNUP_SUCCESS:
      return {
        ...state,
        status: ACTIONS.SIGNUP_SUCCESS,
      };

    case ACTIONS.SIGNUP_FAILURE:
      return {
        ...state,
        status: ACTIONS.SIGNUP_FAILURE,
        error: action.error,
      };

    case ACTIONS.SIGNUP_REQUEST:
      return {
        ...state,
        status: ACTIONS.SIGNUP_REQUEST,
      };

    case ACTIONS.UPDATE_USERSETTINGS_SUCCESS:
      return {
        ...state,
        status: ACTIONS.UPDATE_USERSETTINGS_SUCCESS,
        user: action.user,
      };

    case ACTIONS.UPDATE_USERSETTINGS_FAILURE:
      return {
        ...state,
        status: ACTIONS.UPDATE_USERSETTINGS_FAILURE,
        error: action.error,
      };

    case ACTIONS.UPDATE_USERSETTINGS_REQUEST:
      return {
        ...state,
        status: ACTIONS.UPDATE_USERSETTINGS_REQUEST,
      };

    default:
      return state;
  }
};
