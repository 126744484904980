import {
  getAssetFileName,
} from '../tools/AssetTools';
import { getAssetIRI } from '../tools/IRITools';
import { getFileToUploadFromIndex } from './mediaLibraryState';

/**
 * Get all assets
 * @param {*} state The State
 */
export const getAssets = (state) => state.mediaLibrary.assets;
export const getCroppedAssets = (state) => state.mediaLibrary.croppedAssets;

export const isUploadInProgress = (state) => {
  const {
    creationProgress,
    uploadProgress,
  } = state.mediaLibrary;
  if ((creationProgress.toCreate.length !== 0)
   || (creationProgress.creationInProgress.length !== 0)
   || (creationProgress.creationDone.length !== 0)
   || (uploadProgress.toUpload.length !== 0)
   || (uploadProgress.uploadInProgress.length !== 0)
   || (uploadProgress.uploadDone.length !== 0)) {
    return true;
  }
  return false;
};

export const getTotalNumberOfAssetsToUpload = (state) => {
  const {
    creationProgress,
  } = state.mediaLibrary;
  const total = creationProgress.toCreate.length
   + creationProgress.creationInProgress.length
   + creationProgress.creationDone.length;
  return total;
};

export const getNumberOfUploadedAssets = (state) => {
  const {
    uploadProgress,
  } = state.mediaLibrary;
  const total = uploadProgress.uploadInProgress.length
   + uploadProgress.uploadDone.length;
  return total;
};

export const getUploadingFiles = (state) => {
  const {
    uploadInProgress,
  } = state.mediaLibrary.uploadProgress;
  const files = [];
  for (let i = 0; i < uploadInProgress.length; i += 1) {
    const file = getFileToUploadFromIndex(uploadInProgress[i].fileIdxInUploadArray);
    if (file !== null) {
      files.push(file);
    }
  }
  return files;
};

/**
 * Get a SELECTOR that will allow to get an asset given its IRI
 * @param {*} state The State
 */
export const getAssetFromIRISelector = (state) => {
  return (iri, displayNotFoundError = true) => {
    const index = state.mediaLibrary.assetsIndexesMap[iri];
    if ((typeof index === 'undefined')
     || (index === null)) {
      if (displayNotFoundError) {
        console.error('Asset not found : ', iri);
      }
      return undefined;
    }
    return state.mediaLibrary.assets[index];
  };
};
export const getAssetFromIRI = (state, iri, displayNotFoundError = true) => {
  const func = getAssetFromIRISelector(state);
  return func(iri, displayNotFoundError);
};

/**
 * Get a SELECTOR that will allow to get a cropped asset given its IRI
 * @param {*} state The State
 */
export const getCroppedAssetFromIRISelector = (state) => {
  return (iri, displayNotFoundError = true) => {
    const index = state.mediaLibrary.croppedAssetsIndexesMap[iri];
    if ((typeof index === 'undefined')
     || (index === null)) {
      if (displayNotFoundError) {
        console.error('Asset not found : ', iri);
      }
      return undefined;
    }
    return state.mediaLibrary.croppedAssets[index];
  };
};
export const getCroppedAssetFromIRI = (state, iri, displayNotFoundError = true) => {
  const func = getCroppedAssetFromIRISelector(state);
  return func(iri, displayNotFoundError);
};

/**
   * Returns an array of assets that already exist among the one that the user wants to upload
   */
export const findAlreadyExistingAssets = (state) => (wantedAssetsFiles) => {
  const assets = getAssets(state);

  const existingAssets = [];
  const newFiles = [];
  wantedAssetsFiles.forEach((assetFile) => {
    const existIdx = assets.findIndex((existingAsset) => {
      return (assetFile.name === getAssetFileName(existingAsset));
    });
    if (existIdx !== -1) {
      existingAssets.push({ ...assets[existIdx] });
    } else {
      newFiles.push(assetFile);
    }
  });

  return { existingAssets, newFiles: [...newFiles] };
};


export const getCroppedAssetsFromAssetSelector = (state) => (asset) => {
  const croppedAssets = getCroppedAssets(state);

  const results = [];
  for (let i = 0; i < croppedAssets.length; i += 1) {
    if (croppedAssets[i].asset === getAssetIRI(asset)) {
      results.push(croppedAssets[i]);
    }
  }

  return results;
};

/**
 * Get the last error
 * @param {*} state The State
 */
export const getLastError = (state) => {
  let errorKey = null;
  if ((state.mediaLibrary.error !== null) && (typeof state.mediaLibrary.error !== 'undefined')) {
    errorKey = state.mediaLibrary.error;
  }
  return errorKey;
};
