import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';

import undoHistoryReducer from '../undoredo/reducer';
import localeReducer from './localeState';
import authentication from './authenticationState';

import utilsReducer from './utilsState';
import pendingRequests from './pendingRequestsState';
import mediaLibrary from './mediaLibraryState';

export default function createRootReducer(history) {
  return combineReducers({
    router: connectRouter(history),
    localeReducer, // Internationalization
    authentication, // User authentication
    undoHistory: undoHistoryReducer, // Undo redo
    utils: utilsReducer,
    mediaLibrary,
    pendingRequests,
  });
}
