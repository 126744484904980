//
// File containing Constants or common variables used along the editor
//
import { defineMessages } from 'react-intl';
import { UAParser } from 'ua-parser-js';

// Common messages
export const commonMessages = defineMessages({
  // Common Errors
  internalError: {
    id: 'error.internalError',
    defaultMessage: 'Internal error : {internalErrorCode}.',
  },
  notLoggedIn: {
    id: 'error.notLoggedIn',
    defaultMessage: 'You may be disconnected, try to log in again',
  },

  // Common Buttons
  cancelButton: {
    id: 'buttons.cancelButton',
    defaultMessage: 'Cancel',
  },
  closeButton: {
    id: 'buttons.closeButton',
    defaultMessage: 'Close',
  },
  saveButton: {
    id: 'buttons.saveButton',
    defaultMessage: 'Save',
  },
  yesButton: {
    id: 'buttons.yesButton',
    defaultMessage: 'Yes',
  },
  noButton: {
    id: 'buttons.noButton',
    defaultMessage: 'No',
  },
  okButton: {
    id: 'buttons.okButton',
    defaultMessage: 'OK',
  },

  // Ratio labels
  ratioVertical: {
    id: 'aspectRatio.ratioVertical',
    defaultMessage: 'Vertical',
  },
  ratioHorizontal: {
    id: 'aspectRatio.ratioHorizontal',
    defaultMessage: 'Horizontal',
  },
  ratioSquare: {
    id: 'aspectRatio.ratioSquare',
    defaultMessage: 'Square',
  },
  ratioUnknown: {
    id: 'aspectRatio.ratioUnknown',
    defaultMessage: 'Unknown',
  },

  // Common Placeholders
  emailPlaceholder: {
    id: 'placeholders.emailPlaceholder',
    defaultMessage: 'user@mail.com',
  },
});

export function isDemoMode() {
  return JSON.parse(process.env.REACT_APP_IS_DEMO_MODE);
}
export function isDevelopmentMode() {
  return JSON.parse(process.env.REACT_APP_IS_DEVELOPMENT_MODE);
}
export function isProductionMode() {
  return JSON.parse(process.env.REACT_APP_IS_PRODUCTION_MODE);
}

// Return app environment worded to auth API
function getClientEnvironment() {
  if (isDemoMode()) {
    return 'demo';
  }
  if (isDevelopmentMode()) {
    return 'dev';
  }
  if (isProductionMode()) {
    return 'prod';
  }
  return '';
}

/**
 * Name of the "autocrop editor"
 */
export const PRODUCT_NAME = 'SkewerLab Autocrop';
export const PRODUCT_SHORTNAME = 'SkwAc';

/**
 * Name of the "company"
 */
export const COMPANY_NAME = 'SkewerLab';

export const ASPECT_RATIOS = {
  VERTICAL: 'vertical',
  HORIZONTAL: 'horizontal',
  SQUARE: 'square',
};

const CONSTANTS = {
  PRODUCT_NAME: 'SkewerLab',
  COMPANY_NAME: 'SkewerLab',
  DEFAULT_LOCALE: 'en',
  client: 'clients/autocrop',
  environment: `environments/${getClientEnvironment()}`,
  API: {
    // eslint-disable-next-line
    USERS_URI: process.env.REACT_APP_API_AUTH_URL,
    // eslint-disable-next-line
    AUTOCROP_URI: process.env.REACT_APP_API_AUTOCROP,
  },
  IRI: {
    ASSETS: 'assets/',
  },
  COMMONERRORS: {
    NOT_LOGGED_IN: 'notLoggedIn',
  },
  TYPE_FIELD: '@type',
  IRI_FIELD: '@id',

  AUTOCROP_RATIOS: [ASPECT_RATIOS.VERTICAL, ASPECT_RATIOS.HORIZONTAL, ASPECT_RATIOS.SQUARE],
};
export default CONSTANTS;

// Aspect Ration labels
export const getAspectRatioMessageID = (aspectRatio) => {
  let message = commonMessages.ratioUnknown;
  if (aspectRatio === ASPECT_RATIOS.VERTICAL) {
    message = commonMessages.ratioVertical;
  } else if (aspectRatio === ASPECT_RATIOS.HORIZONTAL) {
    message = commonMessages.ratioHorizontal;
  } else if (aspectRatio === ASPECT_RATIOS.SQUARE) {
    message = commonMessages.ratioSquare;
  }
  return message;
};// getAspectRatioMessageID

// User Agent constants (parsed by UA-Parser)
const uaparser = new UAParser();
export const CURRENT_OS = uaparser.getOS();
export const CURRENT_BROWSER = uaparser.getBrowser();
export const IS_MACOS = CURRENT_OS.name === 'Mac OS';

export const IS_SAFARI = CURRENT_BROWSER.name === 'Safari';

// -------------------------------------------
export const TIMEOUT_BEFORE_REDIRECT_USER = 5000;

/**
 * Defines of all tabs used in the autocrop
 */
export const TABS = {
  TAB_MEDIA_LIBRARY: 'tabMedias',
  TAB_RESULTS: 'tabResults',
};

// Upload status
export const UPLOAD_STATUS = {
  START: '/cloud_resource_upload_statuses/upload_started',
  SUCCESS: '/cloud_resource_upload_statuses/success',
  ERROR: '/cloud_resource_upload_statuses/failed',
  PENDING: '/cloud_resource_upload_statuses/pending',
  NOT_FOUND: '/cloud_resource_upload_statuses/not_found',
  NOT_VERIFIED: '/cloud_resource_upload_statuses/not_verified',
};

export const isUploadPending = (status) => (status === UPLOAD_STATUS.PENDING);
export const isResourceNotFound = (status) => (status === UPLOAD_STATUS.NOT_FOUND);
export const isSuccessfullyUploaded = (status) => (status === UPLOAD_STATUS.SUCCESS);
export const isUploading = (status) => (status === UPLOAD_STATUS.START);
export const uploadFailed = (status) => (status === UPLOAD_STATUS.ERROR);
export const uploadStatusUnknown = (status) => {
  return ((status !== UPLOAD_STATUS.START)
  && (status !== UPLOAD_STATUS.SUCCESS)
  && (status !== UPLOAD_STATUS.PENDING)
  && (status !== UPLOAD_STATUS.ERROR));
};
