import React, { useState } from 'react';
import { connect } from 'react-redux';
import { injectIntl, defineMessages } from 'react-intl';
import classNames from 'classnames';
import { makeStyles } from '@material-ui/core/styles';
import {
  SkwrEditableLabel,
  SkwrHighlightableLabel,
  SkwrTooltip,
  ColorByUsage,
  FontSizes,
  Shadows,
  Color,
} from 'skewerui';
// import { getAssetThumbnailURLSelector } from '../reducers/mediaLibrarySelector';
import {
  isAssetUploaded,
  isAssetStatusNOK,
  isAssetPreviewReady,
  isAssetStatusUploadPending,
  isAssetStatusUploading,
  getAssetFileName,
  getAssetFileNameField,
  getAspectRatioIcon,
} from '../tools/AssetTools';
import { ASPECT_RATIOS } from '../Constants';
import { ReactComponent as AspectRatioHorizontalSVG } from '../assets/svg/aspectRatioHorizontal.svg';
import { ReactComponent as AspectRatioVerticalSVG } from '../assets/svg/aspectRatioVertical.svg';
import { ReactComponent as AspectRatioSquareSVG } from '../assets/svg/aspectRatioSquare.svg';

const messages = defineMessages({
  uploadPendingStatus: {
    id: 'assetItem.uploadPendingStatus',
    defaultMessage: 'Upload pending',
  },
  uploadingStatus: {
    id: 'assetItem.uploadingStatus',
    defaultMessage: 'Upload in progress',
  },
  errorStatus: {
    id: 'assetItem.errorStatus',
    defaultMessage: 'Error',
  },
  croppedVersionAvailableHorizontal: {
    id: 'assetItem.croppedVersionAvailableHorizontal',
    defaultMessage: 'Horizontal version available',
  },
  croppedVersionAvailableVertical: {
    id: 'assetItem.croppedVersionAvailableVertical',
    defaultMessage: 'Vertical version available',
  },
  croppedVersionAvailableSquare: {
    id: 'assetItem.croppedVersionAvailableSquare',
    defaultMessage: 'Square version available',
  },
  generateCroppedVersionHorizontal: {
    id: 'assetItem.generateCroppedVersionHorizontal',
    defaultMessage: 'Generate horizontal version',
  },
  generateCroppedVersionVertical: {
    id: 'assetItem.generateCroppedVersionVertical',
    defaultMessage: 'Generate vertical version',
  },
  generateCroppedVersionSquare: {
    id: 'assetItem.generateCroppedVersionSquare',
    defaultMessage: 'Generate square version',
  },
});

const useStyles = makeStyles(() => ({
  gridListItemContainer: {
    width: '100%',
    height: '100%',
    opacity: '100%',
    borderRadius: '4px',
    boxShadow: Shadows.shadowBlurLight,

    position: 'relative',
  },
  assetStatusStrip: {
    width: '4px',
    height: '100%',
    position: 'absolute',

    borderRadius: '4px',

    zIndex: '1',
  },
  assetStatusStripError: {
    borderLeftWidth: '4px',
    borderLeftColor: ColorByUsage.errorColor,
    borderLeftStyle: 'outset',
  },
  gridListItem: {
    position: 'absolute',
    width: '100%',
    height: '100%',

    display: 'flex',
    flexDirection: 'column',

    zIndex: '0',
  },
  gridListItemError: {
  },
  backgroundUnselected: {
    backgroundColor: ColorByUsage.cardBackground,
  },
  backgroundSelected: {
    backgroundColor: ColorByUsage.cardBackgroundSelected,
  },
  assetHeader: {
    flex: '0 0 auto',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    padding: '8px',
  },
  assetName: {
    margin: '2px 0px 1px 0px',
  },
  assetThumbnailArea: {
    width: '100%',
    height: '100%',

    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
  },
  assetThumbnail: {
    width: '100%',
    height: '100%',

    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundColor: ColorByUsage.cardThumbnailBackground,

    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    position: 'absolute',
    zIndex: 0,
  },
  assetThumbnailImg: {
    width: '100%',
    height: '100%',
    objectFit: 'contain',

    position: 'absolute',
    zIndex: 0,
  },
  assetStatusLabel: {
    opacity: '1.0',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    zIndex: 1,

    borderRadius: '16px',
    paddingLeft: '8px',
    paddingRight: '8px',
    paddingTop: '4px',
    paddingBottom: '4px',
  },
  assetStatusLabelStandard: {
    backgroundColor: ColorByUsage.cardBackground,
    color: ColorByUsage.cardForeground,
  },
  assetStatusLabelError: {
    backgroundColor: ColorByUsage.errorColor,
    color: ColorByUsage.errorForeground,
  },
  assetSourceFile: {
    position: 'absolute',
    bottom: '0px',
    left: '0px',
    color: ColorByUsage.cardBackground,
    width: '100%',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    fontSize: FontSizes.textSmall,
    pointerEvents: 'none',
    textShadow: '1px 1px 2px black',
  },
  assetLabelSelector: {
    minHeight: 30,
    display: 'flex',
    alignItems: 'center',
  },
  assetFooter: {
    minHeight: '25%',
    padding: '8px',
    display: 'flex',
    flex: '1',
  },
  croppedSvg: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '33%',
    '& svg': {
      width: '24px',
      height: '24px',
    },
  },
  availableCrop: {
    border: '1px',
    '& svg': {
      fill: Color.greyDark,
    },
  },
  unavailableCrop: {
    '& svg': {
      fill: Color.greyLight2,
    },
    // Keep the hover effect in case we want to use the indicator to run a crop
    // '&:hover': {
    //   '& svg': {
    //     fill: Color.black,
    //   },
    // },
  },
  clickableCrop: {
    cursor: 'pointer',
  },
}));

const AssetItem = (props, ref) => {
  const {
    className,
    intl: { formatMessage },
    asset,
    onAssetNameChanged,
    selected,
    getAssetThumbnailURL,
    displayRealFileName,
    croppedAssets,
    onGenerateCroppedVersion,
  } = props;

  const [displayOriginalFileName, setDisplayOriginalFileName] = useState(false); // false

  const handleMouseOver = () => {
    const displayFileName = (asset.name !== getAssetFileName(asset));
    if (displayFileName !== displayOriginalFileName) {
      setDisplayOriginalFileName(displayFileName);
    }
  };
  const handleMouseOut = () => {
    if (displayOriginalFileName) {
      setDisplayOriginalFileName(false);
    }
  };
  const handleFocus = () => {
  };
  const handleBlur = () => {
  };
  const handleOnDoubleClick = (evt) => {
    evt.stopPropagation();
  };

  const handleAssetNameChanged = (newAssetName) => {
    if ((typeof onAssetNameChanged !== 'undefined')
     && (onAssetNameChanged !== null)) {
      onAssetNameChanged(newAssetName);
    }
  };

  const handleClickOnCropIndicator = (ratio) => {
    if ((typeof onGenerateCroppedVersion !== 'undefined')
     && (onGenerateCroppedVersion !== null)) {
      onGenerateCroppedVersion(asset, ratio);
    }
  };

  const classes = useStyles();

  // let bgclasses;
  let statusLabelClasses = classNames(classes.assetStatusLabel, classes.assetStatusLabelStandard);
  let statusStripClasses = classes.assetStatusStrip;
  let statusMessage;
  if (isAssetStatusNOK(asset)) {
    statusMessage = formatMessage(messages.errorStatus);
    statusLabelClasses = classNames(classes.assetStatusLabel, classes.assetStatusLabelError);
    statusStripClasses = classNames(classes.assetStatusStrip, classes.assetStatusStripError);
  } else if (isAssetStatusUploadPending(asset)) {
    statusMessage = formatMessage(messages.uploadPendingStatus);
  } else if (isAssetStatusUploading(asset)) {
    statusMessage = formatMessage(messages.uploadingStatus);
  }

  const mainClassName = classNames(className,
    classes.gridListItemContainer,
    selected ? classes.backgroundSelected : classes.backgroundUnselected,
    isAssetStatusNOK(asset) ? classes.gridListItemError : null);

  const thumbnailStyle = {};
  if (isAssetStatusUploadPending(asset)) {
    thumbnailStyle.filter = 'blur(2px)';
    thumbnailStyle.opacity = 0.6;
  } else if (isAssetStatusUploading(asset)) {
    thumbnailStyle.filter = 'blur(1px)';
    thumbnailStyle.opacity = 0.8;
  }
  let displayVideoThumbnail = false;
  // let thumbnail = '';
  const thumbnail = '';
  if (isAssetPreviewReady(asset)) {
    // thumbnail = getAssetThumbnailURL(asset);
    displayVideoThumbnail = true;
  }

  const isGlowing = (elemToGlow) => {
    if (asset.glow && asset.glow.target === elemToGlow) {
      return { glowing: true, text: asset.glow.text };
    }
    return { glowing: false, text: '' };
  };

  const isCroppedRatioAvailable = (ratio) => {
    let avail = false;
    for (let i = 0; (i < croppedAssets.length) && (!avail); i += 1) {
      if (croppedAssets[i].aspectRatio === ratio) {
        avail = true;
      }
    }// for
    return avail;
  };// isSquareCroppedAvailable

  /**
   * Render the crop indicator for given ratio
   * @param {*} ratio
   * @returns
   */
  const renderCropIndicator = (ratio) => {
    let icon = <div />;
    let tooltipAvailable = '';
    let tooltipGenerate = '';
    if (ratio === ASPECT_RATIOS.HORIZONTAL) {
      tooltipAvailable = formatMessage(messages.croppedVersionAvailableHorizontal);
      tooltipGenerate = formatMessage(messages.generateCroppedVersionHorizontal);
      icon = <AspectRatioHorizontalSVG />;
    } else if (ratio === ASPECT_RATIOS.VERTICAL) {
      tooltipAvailable = formatMessage(messages.croppedVersionAvailableVertical);
      tooltipGenerate = formatMessage(messages.generateCroppedVersionVertical);
      icon = <AspectRatioVerticalSVG />;
    } else if (ratio === ASPECT_RATIOS.SQUARE) {
      tooltipAvailable = formatMessage(messages.croppedVersionAvailableSquare);
      tooltipGenerate = formatMessage(messages.generateCroppedVersionSquare);
      icon = <AspectRatioSquareSVG />;
    }

    // A cropped version is available
    if (isCroppedRatioAvailable(ratio)) {
      return (
        <SkwrTooltip title={tooltipAvailable}>
          <div className={classNames(classes.croppedSvg, classes.availableCrop)}>
            {getAspectRatioIcon(ratio)}
          </div>
        </SkwrTooltip>
      );
    }

    // No crop version - and croppable asset
    if (isAssetUploaded(asset)) {
      return (
        // Keep the tooltip in case we want to use the indicator to run a crop
        // <SkwrTooltip title={tooltipGenerate}>
        <div
          // Keep the role in case we want to use the indicator to run a crop
          // role="button"
          className={
            // Keep the clickableCrop classe in case we want to use the indicator to run a crop
            // classNames(classes.croppedSvg, classes.unavailableCrop, classes.clickableCrop)
            classNames(classes.croppedSvg, classes.unavailableCrop)
          }
          // Keep the click handler in case we want to use the indicator to run a crop
          // onClick={() => handleClickOnCropIndicator(ratio)}
        >
          {icon}
        </div>
        // </SkwrTooltip>
      );
    }

    // No crop version - and UNCROPPABLE asset
    return (
      <div className={classNames(classes.croppedSvg, classes.unavailableCrop)}>
        {icon}
      </div>
    );
  };

  return (
    <div
      className={mainClassName}
      onMouseOver={handleMouseOver}
      onMouseOut={handleMouseOut}
      onFocus={handleFocus}
      onBlur={handleBlur}
      onDoubleClick={handleOnDoubleClick}
    >
      <div className={statusStripClasses} />
      <div className={classes.gridListItem}>
        <div className={classes.assetHeader}>
          <SkwrEditableLabel
            className={classes.assetName}
            value={asset.name}
            onValueChanged={handleAssetNameChanged}
            glowData={isGlowing('title')}
          />
        </div>
        <div className={classes.assetThumbnailArea}>
          {displayVideoThumbnail && (
          <img
            loading="lazy"
            src={thumbnail}
            alt=""
            style={thumbnailStyle}
            className={classes.assetThumbnailImg}
          />
          )}
          { statusMessage && (
            <label className={statusLabelClasses}>{statusMessage}</label>
          )}
          {displayRealFileName && (
            <SkwrHighlightableLabel
              labelText={getAssetFileName(asset)}
              glowData={isGlowing(getAssetFileNameField(asset))}
              hllClass={classes.assetSourceFile}
              display={displayOriginalFileName}
            />
          )}
        </div>
        <div className={classes.assetFooter}>
          {renderCropIndicator(ASPECT_RATIOS.HORIZONTAL)}
          {renderCropIndicator(ASPECT_RATIOS.VERTICAL)}
          {renderCropIndicator(ASPECT_RATIOS.SQUARE)}
        </div>
      </div>
    </div>
  );
};

// export default connect((state) => ({
//   getAssetThumbnailURL: getAssetThumbnailURLSelector(state),
// }))(injectIntl(AssetItem));

export default injectIntl(AssetItem);
