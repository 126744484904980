import React, { useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import {
  SkwrTooltip,
  Color,
  ColorByUsage,
  FontSizes,
} from 'skewerui';

const useStyles = makeStyles(theme => ({
  mainDiv: {
    display: 'flex',
    alignItems: 'center',
    margin: '0px 10px',
    width: '220px',
  },
  loaderInfos: {
    margin: '0px 10px',
  },
  import: {
    color: Color.greyDark,
  },
  filename: {
    color: Color.grey,
    fontSize: FontSizes.textSmall,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    width: '150px',
    textAlign: 'initial',
    whiteSpace: 'nowrap',
    pointerEvents: 'none',
  },
  percent: {
    color: Color.greyDark,
    position: 'relative',
    right: '32px',
    fontSize: FontSizes.textMedium,
    width: '0px',
  },
  aroundProgress: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  circleCreate: {
    stroke: ColorByUsage.progressCreateAsset,
  },
  circleUpload: {
    stroke: ColorByUsage.progressUploadAsset,
  },
  circle: {
    stroke: Color.red,
  },
}));


export default function AssetLoader(props) {
  const {
    uploadingFiles,
    nbUploadDone,
    nbUploadTotal,
    uploadInProgress,
    uploadProgressionText,
  } = props;

  const classes = useStyles();
  const labelRef = useRef(null);

  const getPercent = () => {
    const currentPercent = (nbUploadDone / nbUploadTotal) * 100;
    if (currentPercent >= 0 && currentPercent <= 99) {
      return Math.round(currentPercent);
    }
    if (currentPercent > 99) {
      return 99;
    }
    return 0;
  };

  const getOpacity = () => {
    if (uploadInProgress) { return 1; }
    return 0;
  };

  const getCircleClass = () => {
    return classes.circleUpload;
  };

  const getCurrentFileName = () => {
    let fileName = '';
    if ((typeof uploadingFiles !== 'undefined')
     && (uploadingFiles !== null)
     && (uploadingFiles.length !== 0)) {
      fileName = uploadingFiles[0].name;
    }
    return fileName;
  };

  return (
    <div className={classes.mainDiv} style={{ opacity: getOpacity() }}>
      <div className={classes.aroundProgress}>
        <CircularProgress
          variant="determinate"
          value={getPercent()}
          classes={{
            circle: getCircleClass(),
          }}
        />
        <div className={classes.percent}>{`${getPercent()}%`}</div>
      </div>
      <SkwrTooltip ellipsisref={labelRef} title={getCurrentFileName()}>
        <div className={classes.loaderInfos}>
          <p className={classes.import}>{`${uploadProgressionText} (${nbUploadDone}/${nbUploadTotal})`}</p>
          <p className={classes.filename} ref={labelRef}>{getCurrentFileName()}</p>
        </div>
      </SkwrTooltip>
    </div>
  );
}
