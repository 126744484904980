/* eslint-disable max-len */
import React from 'react';
import classNames from 'classnames';
import { makeStyles } from '@material-ui/core/styles';
import { injectIntl, defineMessages } from 'react-intl';
import {
  SkwrCheckbox,
  SkwrCheckboxUpDown,
  SkwrCheckboxStyles,
  Color,
  isKeyEventForSelectAll,
} from 'skewerui';
import ResultItem from './ResultItem';
import { getAssetIRI } from '../tools/IRITools';
import { ASPECT_RATIOS } from '../Constants';

import {
  setPreference,
  getPreference,
  PREFERENCES,
} from '../helpers/preferences';

const messages = defineMessages({
  headerListDates: {
    id: 'croppedAssetsList.headerListDates',
    defaultMessage: 'Dates',
  },
  headerListNames: {
    id: 'croppedAssetsList.headerListNames',
    defaultMessage: 'Names',
  },
  headerListActions: {
    id: 'croppedAssetsList.headerListActions',
    defaultMessage: 'Actions',
  },
  headerListRatio: {
    id: 'croppedAssetsList.headerListRatio',
    defaultMessage: 'Aspect Ratio',
  },
});

const useStyles = makeStyles(() => ({
  exportListMainDiv: {
    flex: '1',
    width: '100%',
    outline: 'none',
    overflow: 'auto',
  },
  assetsTable: {
    width: '95%',
    marginLeft: 'auto',
    marginRight: 'auto',
    tableLayout: 'fixed',
  },
  exportListHeader: {
    height: '30px',
    border: `1px solid ${Color.greyLight3}`,
    backgroundColor: Color.greyLight,
    textTransform: 'uppercase',
  },
  checkBox: {
    width: '45px',
    height: '20px',
    padding: '0px',
  },
  alignleft: {
    textAlign: 'left',
  },
  alignRight: {
    textAlign: 'right',
  },
  dateTableCase: {
    width: '170px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  exportInfos: {
    margin: '0px 70px 0px 5px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    minWidth: '70px',
  },
  action: {
    textAlign: 'right',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  aspectRatioColumn: {
    width: '200px',
  },
  actionColumn: {
    width: '200px',
  },
  btnSort: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    transition: 'all 0.25s ease',
    borderRadius: '5px',
    height: '30px',
  },
  iconBtn: {
    height: '30px',
    width: '30px',
    padding: '0px',
  },
  clickableText: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    borderRadius: '5px',
    transition: 'all 0.25s ease',
    padding: '0px 5px',
    '&:hover': {
      backgroundColor: `${Color.black}15`,
    },
  },
  unclickableText: {
    height: '100%',
    alignItems: 'center',
    padding: '0px 5px',
  },
  theadStick: {
    position: 'sticky',
    top: '0',
    zIndex: '2',
  },
}));

function resultsList(props) {
  const {
    croppedAssets,
    selectedAssets,
    onClickExportAction,
    onCheckOne,
    onCheckAll,
    onDelete,
    onDownload,
    intl: { formatMessage },
  } = props;

  const classes = useStyles();

  const [sortExport, setSortExport] = React.useState(
    getPreference(
      PREFERENCES.CROPPED_ASSETS_SORT_ORDER,
      ['date', 'name', 'ratio'],
    ),
  );
  const [arrowToggle, setArrowToggle] = React.useState(
    getPreference(
      PREFERENCES.CROPPED_ASSETS_SORT_DIRECTION,
      {
        date: false,
        name: false,
        ratio: false,
      },
    ),
  );

  const isAllChecked = () => {
    return ((croppedAssets.length === selectedAssets.length) && (croppedAssets.length !== 0));
  };

  const handleClickHeaderSort = (sortzone) => {
    sortOn(sortzone, false);
  };

  const sortOn = (sortzone, forceRevert) => {
    if ((sortzone === sortExport[0]) || forceRevert) {
      // revert sort order
      revertSortOrder(sortzone);
    }

    const newArrowArray = sortExport.slice();

    newArrowArray.unshift(sortzone);
    for (let i = 1; i < newArrowArray.length; i += 1) {
      if (newArrowArray[i] === sortzone) {
        newArrowArray.splice(i, 1);
      }
    }

    setSortExport(newArrowArray);

    setPreference(PREFERENCES.CROPPED_ASSETS_SORT_ORDER, newArrowArray);
  };

  const handleClickHeaderRevertOrder = (sortzone) => {
    sortOn(sortzone, true);
  };

  const revertSortOrder = (sortzone) => {
    const newArrowToggle = { ...arrowToggle };
    newArrowToggle[sortzone] = !newArrowToggle[sortzone];
    setArrowToggle(newArrowToggle);

    setPreference(PREFERENCES.CROPPED_ASSETS_SORT_DIRECTION, newArrowToggle);
  };

  const compareAssetsNames = (export1, export2) => {
    // Use toUpperCase() to ignore character casing
    const exportName1 = export1.name.toUpperCase();
    const exportName2 = export2.name.toUpperCase();

    let result = 0;
    if (exportName1 > exportName2) {
      result = arrowToggle.name ? -1 : 1;
    } else if (exportName1 < exportName2) {
      result = arrowToggle.name ? 1 : -1;
    }
    return result;
  };

  const compareDates = (date1, date2) => {
    if (arrowToggle.date === false) {
      return new Date(date2.createdAt) - new Date(date1.createdAt);
    }
    return new Date(date1.createdAt) - new Date(date2.createdAt);
  };

  const getAspectRatioRange = (aspectRatio) => {
    let range = 0;
    if (aspectRatio === ASPECT_RATIOS.HORIZONTAL) {
      range = 0;
    } else if (aspectRatio === ASPECT_RATIOS.VERTICAL) {
      range = 1;
    } else if (aspectRatio === ASPECT_RATIOS.SQUARE) {
      range = 2;
    }
    return range;
  };
  const compareAssetsRatio = (asset1, asset2) => {
    const range1 = getAspectRatioRange(asset1.aspectRatio);
    const range2 = getAspectRatioRange(asset2.aspectRatio);
    return arrowToggle.ratio ? (range1 - range2) : (range2 - range1);
  };

  const cmpFunctions = {
    date: compareDates,
    name: compareAssetsNames,
    ratio: compareAssetsRatio,
  };

  const compareCroppedAssets = (croppedAsset1, croppedAsset2) => {
    let result = 0;
    for (let i = 0; (i < sortExport.length) && (result === 0); i += 1) {
      const sortCriterium = sortExport[i];
      result = cmpFunctions[sortCriterium](croppedAsset1, croppedAsset2);
    }
    return result;
  };

  const handleOnCheckSingleItem = (assetCheckChange) => {
    onCheckOne(assetCheckChange);
  };

  const handleDownloadSingleAsset = (assetToDownload) => {
    onDownload(assetToDownload);
  };

  const isChecked = (asset) => {
    const idx = selectedAssets.findIndex((selAss) => {
      return (getAssetIRI(selAss) === getAssetIRI(asset));
    });
    return (idx !== -1);
  };

  const renderSortedAssets = () => {
    const sortedAssets = croppedAssets.slice();

    sortedAssets.sort(compareCroppedAssets);

    const assetsItems = [];
    for (let i = 0; i < sortedAssets.length; i += 1) {
      assetsItems.push(<ResultItem
        key={`croppedAssetItem_${i}`}
        croppedAsset={sortedAssets[i]}
        onClickAction={onClickExportAction}
        onCheckItem={handleOnCheckSingleItem}
        isChecked={isChecked(sortedAssets[i])}
        onDownload={handleDownloadSingleAsset}
      />);
    }// for
    return assetsItems;
  };

  const keyPressedOnExportList = (evt) => {
    if (evt.key === 'Delete') {
      onDelete();
      return;
    }

    if (isKeyEventForSelectAll(evt)) {
      onCheckAll(true);
      evt.preventDefault();
    }
  };

  const handleChangeCheckAll = (evt) => {
    onCheckAll(evt.target.checked);
  };

  return (
    <div
      className={classes.exportListMainDiv}
      autoFocus // eslint-disable-line
      onKeyDown={keyPressedOnExportList}
      role="button"
      tabIndex="-1"
    >
      <table className={classes.assetsTable}>
        <thead className={classes.theadStick}>
          <tr className={classes.exportListHeader}>
            <th className={classes.checkBox}>
              <SkwrCheckbox
                componentStyle={SkwrCheckboxStyles.PRIMARY}
                checked={isAllChecked()}
                onChange={handleChangeCheckAll}
              />
            </th>
            <th className={classNames(classes.alignleft, classes.dateTableCase)}>
              <div
                className={classNames(classes.exportInfos, classes.btnSort)}
                role="button"
                onClick={() => handleClickHeaderSort('date')}
              >
                <div className={classes.clickableText}>
                  {formatMessage(messages.headerListDates)}
                </div>
                <SkwrCheckboxUpDown
                  className={classes.iconBtn}
                  checked={!arrowToggle.date}
                  onChange={() => {
                    handleClickHeaderRevertOrder('date');
                  }}
                />
              </div>
            </th>
            <th className={classes.alignleft}>
              <div
                className={classNames(classes.exportInfos, classes.btnSort)}
                role="button"
                onClick={() => handleClickHeaderSort('name')}
              >
                <div className={classes.clickableText}>
                  {formatMessage(messages.headerListNames)}
                </div>
                <SkwrCheckboxUpDown
                  className={classes.iconBtn}
                  checked={!arrowToggle.name}
                  onChange={() => {
                    handleClickHeaderRevertOrder('name');
                  }}
                />
              </div>
            </th>
            <th className={classNames(classes.aspectRatioColumn, classes.alignleft)}>
              <div
                className={classNames(classes.btnSort)}
                role="button"
                onClick={() => handleClickHeaderSort('ratio')}
              >
                <div className={classes.clickableText}>
                  {formatMessage(messages.headerListRatio)}
                </div>
                <SkwrCheckboxUpDown
                  className={classes.iconBtn}
                  checked={!arrowToggle.ratio}
                  onChange={() => {
                    handleClickHeaderRevertOrder('ratio');
                  }}
                />
              </div>
            </th>
            <th className={classNames(classes.actionColumn, classes.alignleft)}>
              <div
                className={classNames(classes.action)}
              >
                <div className={classes.unclickableText}>
                  {formatMessage(messages.headerListActions)}
                </div>
              </div>
            </th>
          </tr>
        </thead>
        <tbody>
          {renderSortedAssets()}
        </tbody>
      </table>

    </div>
  );
}

export default injectIntl(resultsList);
